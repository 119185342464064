import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { SEO, Layout } from '@/blocks/layout'
import { Video } from '~/features/insights'

export { globals } from '@/lib/global-styles'

const pageTitle = 'SAMexpert TV – our YouTube channel'
const pageDescription =
  'Learn how to navigate through the maze of Microsoft licenses and Cloud services. We regularly publish our best tips on YouTube for you.'

const VideoPage = () => {
  return (
    <Layout>
      <Video pageTitle={pageTitle} />
    </Layout>
  )
}

export default VideoPage

export const Head = ({ data }) => {
  const seoImage = getImage(data.seoImage.image.fixed).images.fallback.src

  return <SEO title={pageTitle} description={pageDescription} image={seoImage} />
}

export const pageQuery = graphql`
  query VideoPageQuery {
    seoImage: contentfulDefaultImage(for: { eq: "SEOVideo" }) {
      image {
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO]
          quality: 10
        )
      }
    }
  }
`
