import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { Wrapper } from '@/UI'
import { slugify } from '@/utils/slugify'
import { colors, desktopSmallMedia } from '@/lib/theme'
import type { Episode } from '../types'
import { BlockDate, BlockCover, BlockPlayer, TitleMain } from './common'

interface Props {
  data: Episode
}

export const MainEpisode = ({ data }: Props) => {
  const { bytes, file, pubDate, id, shortDescription, image, title, podcast } = data
  const { cdnFolder } = podcast

  return (
    <Container>
      <Wrapper $noMobilePadding={false}>
        <Link to={`/podcast/${slugify(title)}`}>
          <Inner $noMobilePadding={false}>
            <MainBlock>
              <Texts>
                <BlockDate inputDate={pubDate} isOnMain />
                <TitleMain isOnMainPage text={title} />
                <Description dangerouslySetInnerHTML={{ __html: shortDescription }} />
              </Texts>
              <BlockPlayer file={file} id={id} bytes={bytes} isOnMain title={title} cdnFolder={cdnFolder} />
            </MainBlock>
            <BlockCover image={image} coverType="main" />
          </Inner>
        </Link>
      </Wrapper>
    </Container>
  )
}

const Inner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-row-gap: 32px;
  cursor: pointer;

  ${desktopSmallMedia} {
    flex-direction: row;
  }
`

const Description = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.63;
  color: ${colors.textStyles.body.inverse};
`

const Container = styled.div`
  display: flex;
  background-color: ${colors.secondary.secondary};
  padding: 24px 0 48px 0;

  ${desktopSmallMedia} {
    padding: 64px 0;
  }
`

const MainBlock = styled.div`
  display: grid;
  flex-shrink: 1;
  flex-grow: 1;
  grid-row-gap: 32px;
  order: 1;

  ${desktopSmallMedia} {
    order: 0;
    max-width: 749px;
    margin-right: 48px;
  }
`
const Texts = styled.div`
  display: grid;
  grid-row-gap: 16px;
`
