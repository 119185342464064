import React from 'react'
import { styled } from '@linaria/react'
import { EpisodeSinglePage } from '../types'
import { BlockCover, BlockDate, TitleMain } from './common'
import { desktopSmallMedia } from '@/lib/theme'
import { Wrapper, RichTextImproved } from '@/UI'

interface Props extends Pick<EpisodeSinglePage, 'title' | 'description' | 'pubDate' | 'image'> {}

export const ExtTopBlock = ({ title, pubDate, image, description }: Props) => {
  return (
    <Container $noMobilePadding={false}>
      <MainBlock>
        <BlockDate inputDate={pubDate} />
        <TitleMain text={title} />
      </MainBlock>
      <BlockCover coverType="single" image={image} />
      <Description content={description} />
    </Container>
  )
}

const Container = styled(Wrapper)`
  display: grid;
  grid-gap: 16px;
  padding-top: 24px;

  ${desktopSmallMedia} {
    grid-template-columns: 380px 1fr;
    padding-top: 64px;
    grid-gap: 58px;
  }
`

const MainBlock = styled.div`
  order: 1;
  display: grid;
  grid-gap: 16px;
  padding-top: 16px;

  ${desktopSmallMedia} {
    padding-top: unset;
    order: unset;
    grid-column: span 2;
  }
`

const Description = styled(RichTextImproved)`
  order: 1;

  ${desktopSmallMedia} {
    order: unset;
  }
`
