import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@linaria/react'
import { podcast, siteUrl } from '_/config/site-config'
import { Button, CloseIcon, Fade, IconButtonCommon } from '@/UI'
import { colors } from '@/lib/theme'
import { ShareIcons } from './ShareIcons'

const { path } = podcast

type Event = React.MouseEvent | React.TouchEvent

interface Props {
  id: string
  title: string
  hideModal: () => void
}

export const ShareModal = ({ id, title, hideModal }: Props) => {
  const url = `${siteUrl}/${path}/${id}`

  const [isSent, setIsSent] = useState(false)

  useEffect(() => {
    if (!isSent) return
    setTimeout(() => {
      setIsSent(false)
    }, 1200)
  }, [isSent])

  const [isClipboardAvailable] = useState(typeof window !== 'undefined' && Boolean(navigator?.clipboard))

  const copy = useCallback(async () => {
    if (!isClipboardAvailable) return

    await navigator.clipboard.writeText(url)
    setIsSent(true)
  }, [isClipboardAvailable, url])

  const containerClickHandler = useCallback((e: Event) => {
    e.stopPropagation()
  }, [])

  return (
    <Fade onFadeClick={hideModal} isNotDark zIndex={100000}>
      <ModalContainer onClick={containerClickHandler}>
        <TopRow>
          <span>Share this podcast</span>
          <IconButtonCommon onClick={hideModal} customColor={colors.secondary.secondary}>
            <CloseIcon size={20} />
          </IconButtonCommon>
        </TopRow>
        <ActionBlock>
          <ShareIcons url={url} title={title} />
          <InputUrl value={url} onChange={() => {}} />
          <StyledButton onClick={copy} state={isSent ? 'isPositive' : null}>
            {isSent? 'Copied' : 'Copy URL'}
          </StyledButton>
        </ActionBlock>
      </ModalContainer>
    </Fade>
  )
}

const StyledButton = styled(Button)`
  width: 136px;
  z-index: 1;
`

const ActionBlock = styled.div`
  display: flex;
`

const InputUrl = styled.input`
  display: flex;
  border-radius: 4px;
  border: 2px solid ${colors.tertiary.tertiary};
  background-color: ${colors.other.white};
  color: ${colors.tertiary.tertiary};
  height: 48px;
  padding: 0 16px;
  align-items: center;
  margin: 0 8px 0 56px;
  width: 306px;
`

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -0.24px;
  text-transform: uppercase;
`

const ModalContainer = styled.div`
  background-color: ${colors.other.white};
  padding: 48px;
  border-radius: 16px;
  color: ${colors.secondary.secondary};
  display: grid;
  grid-row-gap: 32px;
  cursor: default;
  box-shadow: 0 4px 64px 0 rgba(0, 0, 0, 0.25);
`
