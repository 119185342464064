import React from 'react'
import type { Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { reWrap } from '@/utils/reWrap'
import { Typography } from './Typography'

export interface RichTextContent {
  raw: string
}

interface Props {
  content: RichTextContent
  className?: string
  isInverse?: boolean
}

export const RichTextImproved = ({ content, className, isInverse }: Props) => {
  if (!content) return null

  return (
    <Typography className={className} as="section" $isInverse={isInverse}>
      {documentToReactComponents(reWrap(content) as Document)}
    </Typography>
  )
}
