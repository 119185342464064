import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface Props {
  isOnMain?: boolean
  inputDate: string
}

export const BlockDate = ({ inputDate, isOnMain = false }: Props) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const date = new Date(inputDate)

  return <Container isOnMain={isOnMain}>{date.toLocaleDateString(undefined, options)}</Container>
}

const Container = styled.span<{ isOnMain: boolean }>`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  text-transform: uppercase;
  color: ${({ isOnMain }) => (isOnMain ? colors.other.white : colors.secondary.secondary)};
  
  ${desktopSmallMedia} {
    font-size: 18px;
  }
`
