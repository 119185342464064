import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import { Wrapper } from '@/UI'

interface Props {
  text: string
  parentNodePath?: string
}

export const BREADCRUMBS_HEIGHT = 48

export const Breadcrumbs = ({ parentNodePath, text }: Props) => {
  return (
    <Outer>
      <Container $noMobilePadding={false}>
        {parentNodePath ? (
          <StyledLink to={parentNodePath}>
            <ArrowContainer>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                <path d="m2.086 10.692 5.906 5.912c.385.385 1.015.388 1.4.004.385-.385.379-1.014-.005-1.398l-4.227-4.226h12.059c.544 0 .992-.44.992-.984s-.448-.985-.992-.985h-12.059l4.226-4.224c.382-.381.365-1.007-.016-1.389-.382-.382-1.001-.382-1.383 0l-5.901 5.905c-.178.178-.29.422-.29.692s.112.515.29.693z" />
              </svg>
            </ArrowContainer>
            <Text>{text}</Text>
          </StyledLink>
        ) : (
          <Text>{text}</Text>
        )}
      </Container>
    </Outer>
  )
}

const Outer = styled.div`
  background-color: ${colors.background.grayLightest};
  height: ${BREADCRUMBS_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Container = styled(Wrapper)`
  display: flex;
  align-items: center;
  color: ${colors.secondary.secondary};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`

const ArrowContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

const Text = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  text-transform: uppercase;
`
