import React from 'react'
import { Index } from 'react-instantsearch'
import { SingleSearchIndex } from '_/config/search/searchIndices'
import { CustomHitsSection } from './components'

export const SearchResult = ({ indices }: { indices: SingleSearchIndex[] }) => {
  return (
    <>
      {indices.map(index => (
        <Index key={index.name} indexName={index.name}>
          <CustomHitsSection sectionName={index.name} />
        </Index>
      ))}
    </>
  )
}
