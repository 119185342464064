import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { CloseIcon, IconButtonCommon } from '@/UI'
import { colors } from '@/lib/theme'
import { MenuListMob } from './components'
import { hideNav, navState } from '../../state'
import { PortalWrapper } from '../common'

export const MenuMobile = () => {
  const { isNavVisible } = useSnapshot(navState)

  return (
    <PortalWrapper closeHandler={hideNav} isVisible={isNavVisible}>
      <CloseButton onButtonClick={hideNav}>
        <CloseIcon />
      </CloseButton>
      <MenuListMob />
    </PortalWrapper>
  )
}

const CloseButton = styled(IconButtonCommon)`
  color: ${colors.other.white};
  margin-right: auto;
`
