import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface Props {
  text: string
  isOnMainPage?: boolean
}

export const TitleSecond = ({ text, isOnMainPage }: Props) => {
  return <Container dangerouslySetInnerHTML={{ __html: text }} $isOnMainPage={isOnMainPage} />
}

const Container = styled.div<{ $isOnMainPage: boolean }>`
  color: ${colors.textStyles.title.regular};
  font-size: 24px;
  font-style: normal;
  font-weight: ${({ $isOnMainPage }) => $isOnMainPage ? 700 : 400};
  line-height: 1.25;
  letter-spacing: -0.01em;

  ${desktopSmallMedia} {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 32px;
    font-style: normal;
  }
`
