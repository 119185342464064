import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { Link } from 'gatsby'
import { Icon } from '@/UI'

export const Social = () => {
  return (
    <Container>
      <Link to="/" title="SAMexpert homepage" className={logoStyles}>
        <img src="/SAMexpert-W.min.svg" alt="SAMexpert logo" width={150} height={44.29} />
      </Link>
      <SocialGrid>
        <a href="https://youtube.com/samexperttv" target="_blank" title="Visit our YouTube channel" rel="noreferrer">
          <Icon icon="Youtube" iconSize={28} />
        </a>
        <a
          href="https://linkedin.com/company/samexpert"
          target="_blank"
          title="Follow SAMexpert on LinkedIn"
          rel="noreferrer"
        >
          <Icon icon="LinkedIn" iconSize={28} />
        </a>
        <a href="https://twitter.com/samexpert" target="_blank" title="Follow SAMexpert on Twitter" rel="noreferrer">
          <Icon icon="Twitter" iconSize={28} />
        </a>
        <a href="https://t.me/samexperttv" target="_blank" title="Join SAMexpert channel on Telegram" rel="noreferrer">
          <Icon icon="Telegram" iconSize={28} />
        </a>
      </SocialGrid>
    </Container>
  )
}

const Container = styled.div`
  grid-area: social;
  display: grid;
  justify-items: left;
  gap: 1em;
  grid-template: 'logo' 'icons';
`
const logoStyles = css`
  grid-area: logo;
  padding: 0;
  line-height: 0;
  height: 45px;
`

const SocialGrid = styled.div`
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  display: grid;
  grid-column-gap: 12px;
  & a {
    font-size: 1em;
    align-self: center;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & svg {
    height: 28px;
    width: auto;
  }
`
