import React from 'react'
import { styled } from '@linaria/react'
import { HighlightType } from '../types'
import { Wrapper } from '@/UI'
import { HighlightSecondaryItem } from './HighlightSecondaryItem'
import { desktopLargeMedia, desktopSmallMedia, modernMobileMedia } from '@/lib/theme'

interface Props {
  data: HighlightType[]
}

export const HighlightsSecondary = ({ data }: Props) => {
  return (
    <ContentWrapper $noMobilePadding={false}>
      {data.map((el, idx) => (
        <HighlightSecondaryItem key={el.contentful_id} data={el} isLast={idx === data.length - 1} />
      ))}
    </ContentWrapper>
  )
}

const ContentWrapper = styled(Wrapper)`
  display: grid;
  justify-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  grid-gap: 48px;

  ${modernMobileMedia} {
    grid-gap: 16px;
  }

  ${desktopSmallMedia} {
    margin-top: 24px;
    margin-bottom: 24px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 48px;
  }

  ${desktopLargeMedia} {
    grid-gap: 24px;
  }
`
