import React from 'react'
import { styled } from '@linaria/react'
import { boxShadow, selectVideo, VideoThumbnail, VideoType } from '@/UI'

interface Props {
  video: VideoType
}

export const VideoGridElement = ({ video }: Props) => {
  const { videoId, title, localThumbnail } = video

  return (
    <Container className={boxShadow} onClick={() => selectVideo(videoId)}>
      <VideoThumbnail picture={localThumbnail} alt={title} />
      <Title>{title}</Title>
    </Container>
  )
}

const Container = styled.article`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 20px;
  padding: 16px 16px 48px 16px;
`
