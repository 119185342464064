import { styled } from '@linaria/react'
import { desktopSmallMedia, modernMobileMedia } from '@/lib/theme'

export const Wrapper = styled.div<{
  $noMobilePadding?: boolean
  $noUltraThinPadding?: boolean
}>`
  margin: 0 auto;
  width: 100%;
  padding: ${({ $noMobilePadding, $noUltraThinPadding }) =>
    !$noUltraThinPadding && !$noMobilePadding ? '0 24px' : 'none'};

  ${modernMobileMedia} {
    padding: ${({ $noMobilePadding }) => (!$noMobilePadding ? '0 24px' : 'none')};
  }

  ${desktopSmallMedia} {
    max-width: 1440px;
    padding: 0 72px;
  }
`
