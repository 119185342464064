import React, { useLayoutEffect } from 'react'
import { ExtTopBlock, ServicesBlock, ExtPlayerBlock, ExtTranscript, ContentWrapper, stopPlay } from './components'
import { EpisodeSinglePage } from './types'
import { ContactForm, SideBlock } from '@/blocks/contact-form'

interface Props {
  data: EpisodeSinglePage
}

export const SingleEpisode = ({ data }: Props) => {
  const { title, bytes, file, pubDate, id, podcast, image, transcript, description, hosts } = data
  const { cdnFolder } = podcast

  useLayoutEffect(() => {
    return () => {
      stopPlay()
    }
  }, [])

  return (
    <ContentWrapper>
      <ExtTopBlock title={title} description={description} pubDate={pubDate} image={image} />
      <ExtPlayerBlock title={title} file={file} bytes={bytes} id={id} cdnFolder={cdnFolder} />
      <ServicesBlock />
      <ExtTranscript transcript={transcript} hosts={hosts} />
      <ContactForm
        title="Connect with Our Experts"
        description="Provide your details and describe your challenges, and a senior expert will get back to you shortly."
        messageHint="Describe your challenges"
      >
        <SideBlock />
      </ContactForm>
    </ContentWrapper>
  )
}
