import React from 'react'
import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface BtnState {
  isOnMain?: boolean
  isPlaying: boolean
}

interface Props extends BtnState {
  onBtnClick: () => void
}

export const PlayButton = ({ isPlaying, onBtnClick, isOnMain = false }: Props) => {
  return (
    <Container
      onClick={onBtnClick}
      isOnMain={isOnMain}
      isPlaying={isPlaying}
      className={cx(isPlaying ? ToPlay : ToStop)}
    >
      <svg viewBox="0 0 48 48" fill="currentColor">
        {isPlaying ? <path d="m28 38v-28h8v28zm-16 0v-28h8v28z" /> : <path d="m16 10.28v28l22-14z" />}
      </svg>
    </Container>
  )
}

const Container = styled.div<BtnState>`
  --btn-size: ${({ isOnMain }) => (isOnMain ? 56 : 40)}px;
  --icon-size: ${({ isOnMain }) => (isOnMain ? 32 : 40)}px;
  --background-color: ${({ isPlaying, isOnMain }) => {
    if (isOnMain && isPlaying) return colors.primary.primary
    if (isOnMain && !isPlaying) return colors.tertiary.tertiary
    return 'transparent'
  }};
  width: var(--btn-size);
  height: var(--btn-size);
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: ${colors.secondary.secondary};
  margin: 0 ${({ isOnMain }) => (isOnMain ? 8 : 0)}px;
  background-color: var(--background-color);
  transition: background-color 10ms ease-in-out 0.5s;

  ${desktopSmallMedia} {
    --btn-size: ${({ isOnMain }) => (isOnMain ? 112 : 80)}px;
    --icon-size: ${({ isOnMain }) => (isOnMain ? 48 : 32)}px;
    --background-color: ${({ isPlaying }) => (isPlaying ? colors.primary.primary : colors.tertiary.tertiary)};
    color: ${({ isOnMain }) => (isOnMain ? colors.secondary.secondary : colors.other.white)};
    margin: 0;
  }

  &:after {
    content: '';
    display: ${({ isOnMain }) => (isOnMain ? 'block' : 'none')};
    position: absolute;
    border-radius: 50%;
    width: var(--btn-size);
    height: var(--btn-size);
    animation-duration: 0.6s;

    ${desktopSmallMedia} {
      display: block;
    }
  }

  & svg {
    width: var(--icon-size);
    height: var(--icon-size);
    z-index: 1;
  }
`

const ToPlay = css`
  &:after {
    background-color: ${colors.primary.primary};
    animation-name: toPlay;

    @keyframes toPlay {
      0% {
        scale: 0;
      }
      99% {
        scale: 1;
      }
      100% {
        scale: 0;
      }
    }
  }
`

const ToStop = css`
  &:after {
    background-color: ${colors.tertiary.tertiary};
    animation-name: toStop;

    @keyframes toStop {
      0% {
        scale: 0;
      }
      99% {
        scale: 1;
      }
      100% {
        scale: 0;
      }
    }
  }
`
