import React, { useEffect } from 'react'
import { styled } from '@linaria/react'
import { useHits, useStats } from 'react-instantsearch'
import { CustomHit } from './CustomHit'
import { SearchResultsData } from '../types'
import { searchQueryHitsCount } from '../../../../state'

interface Props {
  sectionName: string
}
export const CustomHitsSection = ({sectionName}: Props) => {
  const { hits } = useHits()
  const { nbHits } = useStats()

  useEffect(() => {
    searchQueryHitsCount.value[sectionName] = Boolean(nbHits)
  }, [nbHits, sectionName])

  if (!nbHits) return null

  return (
    <ResultsWrapper>
      {hits.map(hit => (
        <CustomHit key={hit.objectID} hit={hit as unknown as SearchResultsData} />
      ))}
    </ResultsWrapper>
  )
}

const ResultsWrapper = styled.div`
  display: grid;
`
