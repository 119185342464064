import React, { useCallback } from 'react'
import { styled } from '@linaria/react'
import { Button, Title, Typography } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'

export const SideBlock = () => {
  const clickHandler = useCallback(() => {
    if (typeof window === 'undefined') return

    window.open('https://calendly.com/d/dvp-b5g-bdw', '_blank', 'noopener,noreferrer')
  }, [])

  return (
    <Root>
      <Title $gap={0} $gapMob={0}>
        Would you like a call instead?
      </Title>
      <Typography>
        Ready to discuss your Microsoft licensing needs? Book a call with us today! Our experts will provide
        personalized guidance to help you navigate Microsoft’s licensing options, ensuring compliance and optimizing
        your software investments.{' '}
      </Typography>
      <Button type="secondary" onClick={clickHandler} desktopWidth="286px">
        Book a call
      </Button>
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  grid-row-gap: 32px;
  align-self: end;
  margin-bottom: 40px;
  background-color: ${colors.primary.lightest};
  padding: 40px 24px;
  
  ${desktopSmallMedia} {
    background-color: transparent;
    padding: unset;
  }
`
