import React, { useCallback } from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { modalState } from '../../state'
import { ShareModal } from './ShareModal'

interface Props {
  id: string
  title: string
  isOnMain?: boolean
}

export const Share = ({ id, title, isOnMain = false }: Props) => {
  const size = isOnMain ? 48 : 32
  const { currentModalId } = useSnapshot(modalState)

  const showModal = useCallback(() => {
    modalState.currentModalId = id
  }, [id])

  const hideModal = useCallback(() => {
    modalState.currentModalId = null
  }, [])

  return (
    <>
      <Container size={size} onClick={showModal}>
        <svg width={size} height={size} viewBox="0 0 32 32" fill="currentColor">
          <circle cx="23.7" cy="6.67" r="4" />
          <circle cx="23.7" cy="25.33" r="4" />
          <circle cx="7.65" cy="16" r="4" />
          <path d="m8.908 13.759 11.887-6.863 1.192 2.065-11.887 6.863z" />
          <path d="m10.48 16.406 11.887 6.863-1.192 2.065-11.887-6.864z" />
        </svg>
      </Container>
      {currentModalId === id && <ShareModal title={title} id={id} hideModal={hideModal} />}
    </>
  )
}

const Container = styled.button<{ size: number }>`
  display: none;
  cursor: pointer;
  color: ${colors.primary.primary};
  margin-left: 24px;
  width: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  ${desktopSmallMedia} {
    display: flex;
  }
`
