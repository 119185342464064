import React from 'react'
import { styled } from '@linaria/react'
import { IconButtonCommon } from '@/UI'
import { desktopSmallMedia } from '@/lib/theme'
import { SearchIcon } from '../common'
import { showSearch } from '../../state'

export const MenuSearchMobile = () => {
  return (
    <Button onButtonClick={showSearch} gridArea="searchMob" title="Search" aria-label="Toggle Search">
      <SearchIcon size={20} />
    </Button>
  )
}

const Button = styled(IconButtonCommon)`
  margin-left: auto;
  
  ${desktopSmallMedia} {
    display: none;
  }
`
