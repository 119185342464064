import React, { ReactNode, MouseEvent, TouchEvent, HTMLAttributeAnchorTarget } from 'react'
import { styled } from '@linaria/react'
import { Ripple } from '@/UI'
import { colors, desktopSmallMedia, hoverMedia } from '@/lib/theme'

type Type = 'dark' | 'light' | 'secondary'
export type ButtonState = 'isPositive' | 'isNegative' | 'isDisabled' | 'isPending'
type Size = 'standard' | 'small'
type DesktopWidth = 'fit-content' | '100%' | string

interface Props {
  type?: Type
  className?: string
  state?: ButtonState
  size?: Size
  children: ReactNode
  rippleDuration?: string
  desktopWidth?: DesktopWidth
  onClick?: () => void
}

export const Button = ({
  children,
  className,
  onClick,
  state,
  rippleDuration,
  desktopWidth = 'fit-content',
  size = 'standard',
  type = 'light',
}: Props) => {
  const clickHandler = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    if (onClick) onClick()
  }

  return (
    <ButtonView
      className={className}
      onClick={clickHandler}
      $type={type}
      $state={state}
      $size={size}
      $desktopWidth={desktopWidth}
      disabled={Boolean(state)}
    >
      {children}
      <Ripple duration={rippleDuration} fillColor={type === 'secondary' ? colors.primary.primary : undefined} />
    </ButtonView>
  )
}

interface RootProps {
  $type: Type
  $size: Size
  $desktopWidth: DesktopWidth
  $state?: ButtonState
}

const ButtonView = styled.button<RootProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  user-select: none;
  flex-shrink: 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 113%;
  letter-spacing: 0.25px;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  pointer-events: ${({ $state }) => ($state ? 'none' : 'all')};
  border-width: 2px;
  border-style: solid;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  height: ${({ $size }) => ($size === 'standard' ? 48 : 36)}px;
  padding: 0 ${({ $size }) => ($size === 'standard' ? 24 : 16)}px;
  color: ${({ $state, $type }) => {
    switch (true) {
      case $type === 'secondary':
        return colors.secondary.secondary
      case $state === 'isDisabled' && $type === 'dark':
        return colors.tertiary.tertiary
      case $state === 'isDisabled' && $type === 'light':
        return colors.primary.lightest
      case $state === 'isPositive':
        return colors.outcome.positive.darkest
      case $state === 'isNegative':
        return colors.outcome.negative.darkest
      default:
        return colors.primary.primary
    }
  }};
  border-color: ${({ $state, $type }) => {
    switch (true) {
      case $type === 'secondary':
        return colors.secondary.secondary
      case $state === 'isDisabled' && $type === 'dark':
        return colors.tertiary.tertiary
      case $state === 'isDisabled' && $type === 'light':
        return colors.primary.lightest
      case $state === 'isPositive':
        return colors.outcome.positive.lightest
      case $state === 'isNegative':
        return colors.outcome.negative.lightest
      default:
        return colors.primary.primary
    }
  }};
  background-color: ${({ $state }) => {
    switch (true) {
      case $state === 'isPositive':
        return colors.outcome.positive.lightest
      case $state === 'isNegative':
        return colors.outcome.negative.lightest
      default:
        return 'transparent'
    }
  }};

  ${desktopSmallMedia} {
    width: ${({ $desktopWidth }) => $desktopWidth};
  }

  ${hoverMedia} {
    &:hover {
      box-shadow: 0 0 7px 0 ${colors.primary.primary};
    }

    &:active {
      box-shadow: none;
    }
  }

  &:after {
    --animation-offset: 20px;
    --stripe-color: ${({ $type }) =>
      $type === 'light' ? colors.primary.lightest : colors.primary.darkest};
    content: '';
    display: ${({ $state }) => ($state === 'isPending' ? 'block' : 'none')};
    z-index: -1;
    position: absolute;
    left: calc(-1 * var(--animation-offset));
    top: 0;
    width: calc(100% + var(--animation-offset));
    height: 100%;
    background: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 5px,
      var(--stripe-color) 5px,
      var(--stripe-color) 8px
    );
    animation-name: MOVE-BG;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes MOVE-BG {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(var(--animation-offset));
      }
    }
  }
`
