import React from 'react'
import { styled } from '@linaria/react'
import { graphql, useStaticQuery } from 'gatsby'
import { Piece } from './Piece'
import { desktopSmallMedia, modernMobileMedia } from '@/lib/theme'

export const Pieces = () => {
  const data = useStaticQuery<Queries.homepageFactoidsQuery>(graphql`
    query homepageFactoids {
      allContentfulFactoid(sort: { weight: DESC }) {
        nodes {
          figure
          text
        }
      }
    }
  `)
  const factoids = data.allContentfulFactoid.nodes

  if (!Array.isArray(factoids) || !factoids.length) return null

  return (
    <Root>
      {factoids.map((it, idx) => (
        <Piece key={it.figure} figure={it.figure} text={it.text} index={idx} />
      ))}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: center;
  max-width: 480px;
  padding: 12px 0;

  ${modernMobileMedia} {
    padding: 12px;
  }

  ${desktopSmallMedia} {
    padding: 16px 0;
    max-width: unset;
    justify-self: unset;
  }
`
