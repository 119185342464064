import React from 'react'
import { styled } from '@linaria/react'
import { theme } from '../gatsby-plugin-theme-ui/index'
import { tabletMedia, desktopLargeMedia } from '@/lib/theme'
import { Icon } from '@/UI'

export const LightHero = styled.div`
  grid-area: hero;
  padding: 2em;
  display: grid;
  gap: 0.5em;
  grid-template: 'image' 'title' 'excerpt' 'extras' 'social';
  justify-items: center;
  background-color: black;
//  background-image: radial-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.0)), url(/oooscillate.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  color: white;
  text-shadow: 0 0 50px black;
  font-size: 32px;
  font-weight: 700;
  line-height: ${theme.lineHeights.heading};
  text-align: center;
  max-width: 88rem;

  ${tabletMedia} {
    font-size: 32px;
  }

  ${desktopLargeMedia} {
    font-size: 32px;
  }
`

export const Excerpt = styled.section`
  padding: 0;
  margin: 0;
  grid-area: excerpt;
  color: white;
  text-shadow: 0 0 3px black;
  line-height: ${theme.lineHeights.body};
  max-width: 44em;
  text-align: center;
  & p {
    padding: inherit;
    margin: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`

export const HeroExtras = styled.aside`
  grid-area: extras;
  display: grid;
  grid-auto-flow: row;
  ${tabletMedia} {
    grid-template-columns: repeat(10, auto);
  }
  & p,
  div,
  a,
  span {
    color: white;
    text-shadow: 0 0 3px black;
    font-size: '.9em';
    line-height: ${theme.lineHeights.body};
    padding: 0;
    margin: 0;
    text-align: center;
  }
  & div {
    padding-top: 0.5em;
    padding-right: 0;

    ${tabletMedia} {
      padding-top: 0;
      padding-right: 1em;
    }
  }
  & a {
    text-decoration: none;
    transition: 0.3s all;
  }
  & a:hover {
    color: ${theme.colors.logoOrange};
  }
  & button {
    background-color: ${theme.colors.secondary};
    transition: 0.3s all;
    margin: 0.5em 0;
  }
  & button:hover {
    background-color: ${theme.colors.primary};
    cursor: pointer;
  }
  & div:not(:first-of-type)::before {
    border-left: 0;
    ${tabletMedia} {
      border-left: 1px solid ${theme.colors.muted};
      content: ' ';
      padding-left: 1em;
    }
  }
`

export const HeroSocial = ({ linkedIn, twitter }) => {
  return (
    <SocialContainer>
      <LinkedIn handle={linkedIn} />
      <Twitter handle={twitter} />
    </SocialContainer>
  )
}

const SocialContainer = styled.aside`
  grid-area: social;
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
  & a {
    text-decoration: none;
    transition: 0.3s all;
    color: white;
  }
  & a:hover {
    color: ${theme.colors.logoOrange};
  }
  & svg {
    height: 2em;
  }
`

const LinkedIn = ({ handle }) => {
  if (!handle) return null
  return (
    <a href={`https://linkedin.com/in/${handle}`} target="_blank" rel="noreferrer">
      <Icon icon="LinkedIn" />
    </a>
  )
}

const Twitter = ({ handle }) => {
  if (!handle) return null
  return (
    <a href={`https://twitter.com/${handle}`} target="_blank" rel="noreferrer">
      <Icon icon="Twitter" />
    </a>
  )
}
