import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { siteUrl } from '_/config/site-config'
import { Wrapper, RichTextImproved } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { TitleSecond } from '~/features/podcast/components/common'
import { ArticleAuthors, AuthorType } from '@/UI/ArticleAuthors'
import { EpisodeSinglePage } from '../types'

interface Props extends Pick<EpisodeSinglePage, 'transcript' | 'hosts'> {}

export const ExtTranscript = ({ transcript, hosts }: Props) => {
  const hasHosts = Array.isArray(hosts) && hosts.length > 0
  const transformedHosts: AuthorType[][] = hosts.map(({ name, avatar, slug }) => [
    { name, img: avatar.gatsbyImageData, slug },
  ])

  return (
    <Wrapper $noMobilePadding={false}>
      <Container>
        <InfoBlock>
          <TitleSecond text="Episode Transcript" />
          {hasHosts && (
            <Hosts>
              {hasHosts &&
                transformedHosts.map(item => (
                  <Link to={`${siteUrl}/team/${item[0].slug}`} key={item[0].name}>
                    <ArticleAuthors authors={item} />
                  </Link>
                ))}
            </Hosts>
          )}
        </InfoBlock>
        <Texts content={transcript} />
      </Container>
    </Wrapper>
  )
}

const Container = styled.div`
  display: grid;
  background-color: ${colors.background.grayLightest};
  grid-row-gap: 32px;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 48px;

  ${desktopSmallMedia} {
    grid-template-columns: 256px auto;
    grid-column-gap: 24px;
    padding: 46px;
    margin-bottom: 56px;
  }
`

const Texts = styled(RichTextImproved)`
  & * {
    margin-bottom: 24px;
  }
`

const InfoBlock = styled.div`
  display: grid;
  grid-row-gap: 32px;

  ${desktopSmallMedia} {
    grid-gap: 48px;
    align-self: start;
  }
`

const Hosts = styled.div`
  display: grid;
  grid-row-gap: 16px;
  padding: 24px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);

  ${desktopSmallMedia} {
    padding: unset;
    border: unset;
  }
`
