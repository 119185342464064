import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, hoverMedia } from '@/lib/theme'

interface Props {
  onBlockClick: () => void
  rate: number
  isOnMain?: boolean
  isColored: boolean
}

export const RateBlock = ({ onBlockClick, rate, isColored, isOnMain = false }: Props) => {
  return (
    <Container isOnMain={isOnMain} onClick={onBlockClick} isColored={isColored}>
      {rate}X
    </Container>
  )
}

const Container = styled.div<{ isOnMain?: boolean; isColored: boolean }>`
  display: flex;
  flex-shrink: 0;
  width: 45px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.35;
  transition: background-color 0.3s ease-in-out;
  color: ${({ isOnMain }) => (isOnMain ? colors.secondary.secondary : colors.other.white)};
  background-color: ${({ isColored }) =>
    isColored ? colors.primary.primary : colors.tertiary.tertiary};
  right: 0;

  ${desktopSmallMedia} {
    position: absolute;
    left: ${({ isOnMain }) => (isOnMain ? 128 : 96)}px;
    bottom: ${({ isOnMain }) => (isOnMain ? 12 : 0)}px;
  }

  ${hoverMedia} {
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: ${({ isColored }) =>
        isColored ? colors.tertiary.tertiary : colors.primary.primary};
    }
  }
`
