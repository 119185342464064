import React from 'react'
import { styled } from '@linaria/react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { LightHero, Title } from '../components/hero'
import { RichText2 } from '../components/richtext'
import { SEO, Layout } from '@/blocks/layout'

const PageTemplate = ({ data }) => {
  const page = data.contentfulPage

  return (
    <Layout>
      <PageWrapper>
        <LightHero style={{ gridArea: 'hero' }}>
          <Title>{page.title}</Title>
        </LightHero>
        <RichText2 style={{ maxWidth: '48em', gridArea: 'content' }} content={page.content} includeToc />
      </PageWrapper>
    </Layout>
  )
}

export default PageTemplate

export const Head = ({ data }) => {
  const page = data.contentfulPage
  const seoImage = getImage(page.seoImage.fixed) ? getImage(page.seoImage.fixed).images.fallback.src : undefined

  return <SEO title={page.title} description={page.seoDescription} image={seoImage} />
}

export const pageQuery = graphql`
  query ContentfulPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      seoDescription
      content {
        raw
        references {
          ... on ContentfulAsset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            contentful_id
            title
          }
          ... on ContentfulYoutubeEmbed {
            contentful_id
            __typename
            videoId {
              videoId
              title
              description
              publishedAt
              duration
              likeCount
              viewCount
              commentCount
              thumbnail {
                url
              }
            }
            title
          }
        }
      }
      seoImage {
        fixed: gatsbyImageData(width: 1200, height: 630, layout: FIXED, formats: [AUTO], quality: 10)
      }
    }
  }
`

const PageWrapper = styled.div`
  display: grid;
  grid-template: 'hero' 'content';
`
