import React, { useMemo, useState } from 'react'
import { useSnapshot, proxy } from 'valtio'
import { Player, VideoType } from '@/UI'
import { VideoGridElement } from './VideoGridElement'
import { ShowMoreBtn, SectionWrapper, Grid } from '../../../common'

const videosBlocksCountState = proxy<{ blocksCountOnScreen: number }>({ blocksCountOnScreen: 12 })

interface Props {
  videos: VideoType[]
}

export const VideoGrid = ({ videos }: Props) => {
  const numVideos = videos.length
  const { blocksCountOnScreen } = useSnapshot(videosBlocksCountState)
  const [blocksCountInc] = useState(blocksCountOnScreen)

  const { showMoreBtn, videosFinal } = useMemo(() => {
    const videosOnScreen = videos.slice(0, blocksCountOnScreen)
    return { videosFinal: videosOnScreen, showMoreBtn: numVideos > videosOnScreen.length }
  }, [blocksCountOnScreen, numVideos, videos])

  const loadMoreVideos = () => {
    videosBlocksCountState.blocksCountOnScreen += blocksCountInc
  }

  return (
    <>
      <SectionWrapper $noMobilePadding={false}>
        <Grid>
          {videosFinal.map(video => (
            <VideoGridElement key={video.videoId} video={video} />
          ))}
        </Grid>
        {showMoreBtn && <ShowMoreBtn onButtonClick={loadMoreVideos} />}
      </SectionWrapper>
      <Player />
    </>
  )
}
