import React, { ReactNode } from 'react'
import { Scrollbars } from 'rc-scrollbars'
import { styled } from '@linaria/react'

interface Props {
  children: ReactNode
}

export function ScrollbarWrapper({ children }: Props) {
  return (
    <Container>
      <Scrollbars autoHide hideTracksWhenNotNeeded>{children}</Scrollbars>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & .rc-scrollbars-container {
    flex-grow: 1;
  }

  & .rc-scrollbars-view {
    display: flex;
    flex-direction: column;
  }
`
