import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, modernMobileMedia } from '@/lib/theme'
import { BlockPlayer, type PlayerProps } from '~/features/podcast/components/common'
import { Wrapper } from '@/UI'

export function ExtPlayerBlock({ id, file, bytes, title, cdnFolder }: PlayerProps) {
  return (
    <Wrapper $noMobilePadding>
      <PlayerWrapper>
        <StyledPlayer title={title} file={file} bytes={bytes} id={id} cdnFolder={cdnFolder} isOnMain isOnSingle />
      </PlayerWrapper>
    </Wrapper>
  )
}

const PlayerWrapper = styled.div`
  display: flex;
  background-color: ${colors.secondary.secondary};
  padding: 16px 8px;
  margin: 48px 0 0 0;

  ${modernMobileMedia} {
    border-radius: 16px;
    padding: 24px 16px;
    margin: 48px 8px 0 8px;
  }

  ${desktopSmallMedia} {
    margin: 64px 0 0 0;
    padding: 32px 48px;
  }
`

const StyledPlayer = styled(BlockPlayer)`
  flex-grow: 1;
`
