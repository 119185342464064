import React, { ReactNode, useEffect } from 'react'
import { useTransition, type Stage } from 'transition-hook'
import { styled } from '@linaria/react'
import { Portal, ScrollbarWrapper, Wrapper } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { ButtonContactCommon } from './ButtonContactCommon'
import { useEscKeyHandler, useMediaQuery } from '@/hooks'

interface Props {
  children: ReactNode[]
  closeHandler: () => void
  isVisible: boolean
}

export const PortalWrapper = ({ children, isVisible, closeHandler }: Props) => {
  const isDesktop = useMediaQuery()
  const { stage, shouldMount } = useTransition(isVisible, isDesktop ? 100 : 200)
  useEscKeyHandler(closeHandler)

  useEffect(() => {
    if (!isVisible) return

    document.body.style.overflow = 'hidden'

    // eslint-disable-next-line consistent-return
    return () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.removeProperty('overflow')
        if (!document.body.style[0]) document.body.removeAttribute('style')
      }
    }
  }, [isVisible])

  return (
    shouldMount && (
      <Portal zIndex={100000}>
        <Container $stage={stage} onClick={closeHandler}>
          <WrapperStyled $noMobilePadding={false}>
            <TopBlock>{children[0]}</TopBlock>
            <ContentInner>
              <ScrollbarWrapper>{children[1]}</ScrollbarWrapper>
            </ContentInner>
            <ContactBtnMobile buttonType="intoMenu" />
          </WrapperStyled>
        </Container>
      </Portal>
    )
  )
}

const ContentInner = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${desktopSmallMedia} {
    margin: 0 135px;
  }
`

const Container = styled.div<{ $stage: Stage }>`
  height: var(--portal-height);
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary.secondary};
  position: fixed;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100vw;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s, 0.15s;
  transition-property: max-height, opacity;
  max-height: ${({ $stage }) => ($stage === 'enter' ? 'var(--portal-height)' : 'var(--header-h)')};
  opacity: ${({ $stage }) => ($stage === 'enter' ? 1 : 0)};
  padding-bottom: 32px;

  ${desktopSmallMedia} {
    padding-bottom: 56px;
    transition-duration: 0.1s, 0.05s;
  }
`

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-h);
  flex-direction: row-reverse;

  ${desktopSmallMedia} {
    flex-direction: row;
  }
`

const ContactBtnMobile = styled(ButtonContactCommon)`
  display: flex;

  ${desktopSmallMedia} {
    display: none;
  }
`

const WrapperStyled = styled(Wrapper)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
