import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { VideoAnnounce } from './VideoAnnounce'
import { Player } from '@/UI'
import { AnnouncesGrid, AnnouncesSection } from '@/blocks/announces'

export const LastVideos = () => {
  const data = useStaticQuery<Queries.VideoAnnouncesQueryQuery>(graphql`
    query VideoAnnouncesQuery {
      youtubePlaylist(originalID: { eq: "PLrzGKxp1pvT_Q0UDDbfARhaFf-Cam7ZOS" }) {
        videos {
          title
          videoId
          localThumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 10)
            }
          }
        }
      }
    }
  `)

  const { videos } = data.youtubePlaylist

  if (!videos?.length) return null

  const videoAnnounces = videos.filter(Boolean).slice(0, 3)

  return (
    <>
      <AnnouncesSection title="Featured Videos:" linkUrl="/video" hasDivider linkText="More videos">
        <AnnouncesGrid>
          {videoAnnounces.map(video => (
            <VideoAnnounce key={video.videoId} video={video} />
          ))}
        </AnnouncesGrid>
      </AnnouncesSection>
      <Player />
    </>
  )
}
