import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import AuthorGrid from '../components/authorgrid'
import { LightHero, Title } from '../components/hero'
import { SEO, Layout } from '@/blocks/layout'

const pageTitle = 'Meet our founders'
const pageDescription =
  'SAMexpert partners and leadership team oversee and participate in every service that we deliver to you. You always get the first class care.'

const TeamIndex = ({ data }) => {
  const team = data.allContentfulAuthor.nodes
  const { defaultImage } = data

  return (
    <Layout>
      <div
        style={{
          display: 'grid',
          gridTemplate: "'hero' 'team'",
          gridTemplateColumns: '100%',
        }}
      >
        <LightHero style={{ gridArea: 'hero' }} fluidImage={defaultImage.image.fluid}>
          <Title>{pageTitle}</Title>
        </LightHero>
        <AuthorGrid authors={team} style={{ gridArea: 'team' }} />
      </div>
    </Layout>
  )
}

export default TeamIndex

export const Head = ({ data }) => {
  const seoImage = getImage(data.seoImage.image.fixed).images.fallback.src

  return <SEO title={pageTitle} description={pageDescription} image={seoImage} />
}

export const pageQuery = graphql`
  query TeamIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAuthor(sort: { order: ASC }, filter: { includeInLeadership: { eq: true } }) {
      nodes {
        id
        name
        slug
        bio {
          bio
          childMarkdownRemark {
            html
          }
        }
        avatar {
          gatsbyImageData(width: 100, placeholder: BLURRED, layout: FIXED, formats: [AUTO, WEBP])
        }
      }
    }
    defaultImage: contentfulDefaultImage(for: { eq: "Author" }) {
      image {
        fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 10)
      }
    }
    seoImage: contentfulDefaultImage(for: { eq: "SEOteam" }) {
      image {
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO]
          quality: 10
        )
      }
    }
  }
`
