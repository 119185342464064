import React from 'react'
import { styled } from '@linaria/react'
import { InputStatusIcon } from '../InputStatusIcon'
import { colors } from '@/lib/theme'
import { TransitionFade } from '@/UI'

interface CheckboxProps {
  isChecked?: boolean
  className?: string
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
}

export function Checkbox({ isChecked, onChange, className }: CheckboxProps) {
  return (
    <Envelope className={className}>
      <HiddenCheckbox type="checkbox" checked={isChecked} onChange={onChange} />
      <CheckboxView $isChecked={isChecked}>
        <TransitionFade isVisible={isChecked} duration={0.2}>
          <InputStatusIcon status="isOk" />
        </TransitionFade>
      </CheckboxView>
    </Envelope>
  )
}

const Envelope = styled.span`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HiddenCheckbox = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  clip: rect(0 0 0 0);
`

const CheckboxView = styled.span<{ $isChecked: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, background-color;
  color: ${({ $isChecked }) => ($isChecked ? colors.outcome.positive.positive : colors.primary.primary)};
  border-color: currentColor;
`
