import React from 'react'
import { css } from '@linaria/core'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import { ExternalLink } from '@/UI'
import { MenuItemType } from '../../../types'
import { hideNav } from '../../../state'

interface Props {
  item: MenuItemType
}

export const MobGroup = ({ item }: Props) => {
  const { title, submenu, external, url } = item

  return (
    <Container>
      {external ? (
        <External href={url} target="_blank" onClick={hideNav}>
          {title}
          <ExternalLink size={20} />
        </External>
      ) : (
        <GroupContainer>
          <Title>{title} /</Title>
          <LinksGroup>
            {submenu.map(({ title: linkTitle, external: linkExternal, url: linkUrl }) =>
              linkExternal ? (
                <External key={`${linkTitle}_${linkUrl}`} href={linkUrl} target="_blank" onClick={hideNav}>
                  {title}
                  <ExternalLink size={20} />
                </External>
              ) : (
                <SingleLink key={`${linkTitle}_${linkUrl}`}>
                  <Link to={linkUrl} activeClassName={ActiveStyle} onClick={hideNav}>
                    {linkTitle}
                  </Link>
                </SingleLink>
              ),
            )}
          </LinksGroup>
        </GroupContainer>
      )}
    </Container>
  )
}

const ActiveStyle = css`
  font-weight: 700;
`

const External = styled.a`
  color: ${colors.tertiary.tertiary};
  display: flex;
  align-items: center;
  padding: 16px 0;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 32px;
`

const Title = styled.span`
  padding: 32px 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.196px;
  text-transform: uppercase;
  color: ${colors.tertiary.tertiary};
`

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const LinksGroup = styled.ul`
  display: grid;
  width: 100%;
`

const SingleLink = styled.li`
  color: ${colors.other.white};
  font-size: 16px;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.224px;
  padding: 16px 0;
  width: 100%;
`
