import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, fontLeagueSpartan, fontLibreBaskerville, hoverMedia } from '@/lib/theme'

export const Typography = styled.div<{ $isInverse?: boolean }>`
  display: flex;
  flex-direction: column;
  font-family: ${fontLibreBaskerville};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.65;
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.body.inverseStrong : colors.textStyles.body.regular)};

  ${desktopSmallMedia} {
    font-size: 16px;
  }

  & p {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  & span {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  & a {
    color: ${colors.other.link};
    font-weight: 500;
    text-decoration: underline;

    ${hoverMedia} {
      &:hover {
        transition: filter 0.4s ease-in-out;
        filter: brightness(70%) grayscale(100%);
      }
    }
  }

  & b {
    font-weight: 700;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-family: ${fontLeagueSpartan};
    margin-top: 16px;
    margin-bottom: 16px;
  }

  & h2 {
    font-size: 24px;
  }

  & h3 {
    font-size: 20px;
  }

  & h4 {
    font-size: 16px;
  }

  & ul {
    list-style-type: disc;
  }

  & ol,
  ul {
    margin-left: 36px;
    margin-bottom: 0;
    & li {
      margin-bottom: 0;
    }
  }

  & table {
    width: 100%;
    margin-bottom: 16px;

    & p {
      margin: 0;
    }

    & th,
    td {
      padding: 8px 16px;
    }

    & th {
      background-color: ${colors.secondary.darkest};
      border: 1px solid ${colors.secondary.darkest};
      color: white;
    }

    & td {
      border: 1px solid ${colors.background.gray};
    }
  }
`
