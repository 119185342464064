import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { styled } from '@linaria/react'
import { LightHero, Title } from '~/components/hero'
import { myTextToHTML } from '@/utils/myTextToHTML'
import { schemaOrgVideo } from '@/utils/schema.org'
import ContactForm from '../components/contactform-new'
import SiteMapSection from '../components/sitemapsection'
import { tabletMedia } from '@/lib/theme'
import { theme } from '../gatsby-plugin-theme-ui/index'
import { SEO, Layout } from '@/blocks/layout'
import { slugify } from '@/utils/slugify'

const VideoTemplate = ({ data }) => {
  const { video } = data
  const relatedNodes = data.related.nodes
  const youtubePlaylist = data.youtubePlaylist.videos.filter(vid => vid !== null)
  const heroImage = getImage(video.localThumbnail.fluid)

  let relatedVideos = []

  // It's a ridiculous workaround due to GraphQL limitations
  // which make it nearly impossible to filter a list of videos
  // by a single playlist but also a single tag
  relatedNodes.forEach(node => {
    node.videos.forEach(vid => {
      if (
        vid.videoId !== video.videoId &&
        !relatedVideos.find(v => v.videoId === vid.videoId) &&
        youtubePlaylist.find(p => p.videoId === vid.videoId)
      ) {
        const relatedVid = vid
        relatedVid.slug = `video/${slugify(vid.title)}`
        relatedVideos.push(relatedVid)
      }
    })
  })

  relatedVideos = relatedVideos.slice(0, 9)

  return (
    <Layout>
      <PageWrapper>
        <LightHero style={{ gridArea: 'hero' }} fluidImage={heroImage}>
          <Title>{video.title}</Title>
        </LightHero>
        <VideoWrapper id="videowrapper">
          <IframeWrapper id="iframewrapper">
            <iframe
              loading="lazy"
              title="YouTube video player"
              type="text/html"
              src={`https://www.youtube.com/embed/${video.videoId}?modestbranding=1&cc_load_policy=1&rel=0&autoplay=1`}
              allowFullScreen
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </IframeWrapper>
        </VideoWrapper>
        <Description
          id="description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: myTextToHTML(video.description.split('+++')[0]),
          }}
        />
        <RelatedVideos>
          <SiteMapSection title="Related videos" pages={relatedVideos} />
        </RelatedVideos>
        <EmbeddedForm>
          <ContactForm />
        </EmbeddedForm>
      </PageWrapper>
    </Layout>
  )
}

const RelatedVideos = styled.aside`
  padding: 1em;
  ${tabletMedia} {
    padding: 2em;
  }
  grid-area: related;
  max-width: 48em;
  justify-self: center;
`

const EmbeddedForm = styled.div`
  width: 90%;
  max-width: 40em;
  grid-area: form;
  margin: 0 2em 2em 2em;
  place-self: start center;
`

const PageWrapper = styled.div`
  display: grid;
  grid-template: 'hero' 'embed' 'description' 'form' 'related';
  grid-template-columns: 100%;
`

const VideoWrapper = styled.div`
  grid-area: embed;
  max-width: 60em;
  width: 100%;
  padding: 1em 1em 0 1em;
  justify-self: center;
  ${tabletMedia} {
    padding: 2em 2em 0 2em;
  }
`

const IframeWrapper = styled.div`
  position: relative;
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
  height: 0;
  overflow: hidden;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Description = styled.div`
  grid-area: description;
  max-width: 48em;
  width: 100%;
  padding: 2em 2em 4em 2em;
  justify-self: center;

  & a {
    color: #00c;
    font-weight: 500;
    transition: all 0.3s;
  }
  & a:hover {
    color: ${theme.colors.logoOrange};
  }
`

export default VideoTemplate

export const Head = ({ data }) => {
  const { video } = data
  const seoImage = getImage(video.localThumbnail.fixed).images.fallback.src

  return (
    <SEO
      title={`${video.title} – VIDEO`}
      description={video.description.substring(0, 160)}
      image={`https://samexpert.com${seoImage}`}
      richresult={schemaOrgVideo(video)}
    />
  )
}

export const pageQuery = graphql`
  query ContentfulVideoById($videoId: String!) {
    site {
      siteMetadata {
        title
      }
    }
    video: youtubeVideo(videoId: { eq: $videoId }) {
      videoId
      title
      description
      publishedAt
      duration
      likeCount
      viewCount
      commentCount
      thumbnail {
        url
      }
      localThumbnail {
        fluid: childImageSharp {
          gatsbyImageData
        }
        fixed: childImageSharp {
          gatsbyImageData(width: 1200, height: 630, quality: 10)
        }
      }
    }
    related: allYoutubeVideoTag(
      filter: { videos: { elemMatch: { videoId: { eq: $videoId } } }, numVideos: { gt: 1 } }
      sort: [{ numVideos: ASC }, { videos: { publishedAt: DESC } }]
    ) {
      nodes {
        videos {
          title
          videoId
        }
      }
    }
    youtubePlaylist(originalID: { eq: "PLrzGKxp1pvT_Q0UDDbfARhaFf-Cam7ZOS" }) {
      id
      videos {
        videoId
      }
    }
  }
`
