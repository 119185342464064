import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { colors } from '@/lib/theme'
import { Checkbox } from './Checkbox'

interface Props {
  isChecked: boolean
  toggleChecked: () => void
  children: ReactNode
}

export const AgreementBase = ({ isChecked, toggleChecked, children }: Props) => {
  return (
    <Container>
      <CheckboxStyled isChecked={isChecked} onChange={toggleChecked} />
      <Text>{children}</Text>
    </Container>
  )
}

const Container = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
`

const CheckboxStyled = styled(Checkbox)`
  margin-right: 12px;
`

const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: ${colors.other.white};
`
