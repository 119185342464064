import { styled } from '@linaria/react'
import { desktopSmallMedia } from '@/lib/theme'

export const AnnouncesGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  align-self: center;

  ${desktopSmallMedia} {
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    align-self: unset;
  }
`
