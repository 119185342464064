import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface Props {
  text: string
  isOnMainPage?: boolean
}

export const TitleMain = ({ text, isOnMainPage }: Props) => {
  return (
    <Styles as={isOnMainPage ? 'h2' : 'h1'} dangerouslySetInnerHTML={{ __html: text }} isOnMainPage={isOnMainPage} />
  )
}

const Styles = styled.h1<{ isOnMainPage: boolean }>`
  color: ${({ isOnMainPage }) => (isOnMainPage ? colors.textStyles.title.inverse : colors.textStyles.title.regular)};
  font-size: 36px;
  font-weight: 600;
  line-height: 1.05;
  letter-spacing: -0.01em;

  ${desktopSmallMedia} {
    font-size: 60px;
  }
`
