import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useRef } from 'react'
import useIntersectionObserver from '@react-hook/intersection-observer'
import { Script } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { slugify } from '@/utils/slugify'
import { schemaOrgVideo, schemaOrgFaq } from '@/utils/schema.org'
import { tabletMedia } from '@/lib/theme'

const richTextPadded = css`
  padding: 1em;
  ${tabletMedia} {
    padding: 2em;
  }
`

const richTextNoPadding = css`
  padding: 0;
`

const RichTextSection = styled.section`
  margin: 0;
  justify-self: center;
  width: 100%;
  & h2,
  h3 {
    margin: 0 0 1em 0;
    line-height: 1.25;
  }
  & h2 {
    font-size: 2em;
    font-weight: 700;
  }
  & h3 {
    font-size: 1.5em;
    font-weight: 500;
  }
  & p {
    font-weight: 400;
    line-height: 1.5;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
  }
  & p,
  blockquote {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
  & ul,
  ol {
    margin: 0 0 2em 2em;
  }
  & ul {
    list-style-type: disc;
  }
  & li p {
    margin: 0 0 1em 0;
  }
  & a {
    color: #00c;
    transition: all 0.1s;
    font-weight: 500;
    text-decoration: none;
  }
  & a:hover {
    color: coral;
  }
  & blockquote {
    padding: 0 1em;
    font-style: italic;
    font-size: 1.25em;
    quotes: '“' '”' '‘' '’';
  }
  & table {
    width: 100%;
    margin-bottom: 2em;
    p {
      margin: 0;
    }
    th,
    td {
      padding: 0.5em 1em;
    }
    th {
      background-color: black;
      color: white;
      border: 1px solid black;
    }
    td {
      border: 1px solid grey;
    }
  }
`

export const RichText = ({ nopadding = false, children, ...props }) => {
  return (
    <RichTextSection {...props} className={nopadding ? richTextNoPadding : richTextPadded}>
      {children}
    </RichTextSection>
  )
}

//
// Rendering options for Table of Contents
//
const tocOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <li>
          <AnchorLink to={`#${slugify(children)}`}>{children}</AnchorLink>
        </li>
      )
    },
  },
}

const TocContainer = styled.nav`
  padding: 0.5em 1em 0 1em;
  margin: 0 0 2em 0 !important;
  background-color: #f3f3f3;
  & a {
    font-weight: 500 !important;
  }
  & h2 {
    margin-bottom: 0.5em;
  }
  & ul {
    list-style-type: none !important;
    padding: 0 0 0.5em 1em;
    margin: 0;
  }
  & li {
    margin-bottom: 0.5em;
  }
`

//
// TOC renderer component
//
const TableOfContents = json => {
  // "raw" json (content.raw) returned by the rich text field
  const { content } = JSON.parse(json)
  const headingTypes = [BLOCKS.HEADING_2]
  const headings = content.filter(item => headingTypes.includes(item.nodeType))

  return (
    headings &&
    headings.length > 2 && (
      <TocContainer>
        <h2>Table of contents:</h2>
        <ul>{documentToReactComponents(document(headings), tocOptions)}</ul>
      </TocContainer>
    )
  )
}

//
const document = items => {
  return {
    nodeType: 'document',
    content: items,
  }
}

//
//  Table of content is inserted before the first H2 in a page/article
//  This function slices the page/article in two parts
//
const sliceContentAtFirstH2 = json => {
  const { content } = JSON.parse(json)
  const headingBlock = BLOCKS.HEADING_2

  const sliceAt = content.findIndex(item => headingBlock === item.nodeType)

  const parts = { preToc: null, postToc: null }

  switch (sliceAt) {
    case -1:
    case 0:
      parts.postToc = content
      break
    default:
      parts.preToc = content.slice(0, sliceAt)
      parts.postToc = content.slice(sliceAt)
  }

  return {
    preToc: parts.preToc && document(parts.preToc),
    postToc: parts.postToc && document(parts.postToc),
  }
}

//
// TODO: make "searchFor" an array, add "FAQ" etc. - only when there's a need
//
const extractFaqSection = json => {
  const { content } = JSON.parse(json)
  const headingBlock = BLOCKS.HEADING_2
  const embeddedBlocks = [BLOCKS.EMBEDDED_ASSET, BLOCKS.EMBEDDED_ENTRY]
  const searchFor = 'Frequently asked questions'

  const firstSliceAt = content.findIndex(
    item =>
      headingBlock === item.nodeType &&
      item.content[0].nodeType === 'text' &&
      item.content[0].value.slice(0, searchFor.length) === searchFor,
  )
  // no FAQ - break
  if (firstSliceAt === -1) return null

  const theRest = content.slice(firstSliceAt)

  const finalSliceAt = theRest.findIndex((item, index) => index > 0 && headingBlock === item.nodeType)
  const faqSectionUnfiltered = finalSliceAt !== -1 ? theRest.slice(0, finalSliceAt) : theRest

  const faqSection = faqSectionUnfiltered.filter(item => !embeddedBlocks.includes(item.nodeType))

  return faqSection
}

//
// After the FAQ section has been extracted with extractFaqSection
// it needs to be converted to an array of questions
//
const normaliseFaqSection = faq => {
  if (!faq) return null

  const headingBlock = BLOCKS.HEADING_3

  const result = []

  let qStart = faq.findIndex(item => headingBlock === item.nodeType)
  let qEnd = faq.findIndex((item, index) => index > qStart && headingBlock === item.nodeType)

  while (qEnd !== -1) {
    result.push({
      question: extractText(faq[qStart]),
      answer: documentToHtmlString(document(faq.slice(qStart + 1, qEnd))),
    })
    // qe and qs are here because eslint was complaining about unsafe variables something
    const qe = qEnd
    qStart = faq.findIndex((item, index) => index >= qe && headingBlock === item.nodeType)
    const qs = qStart
    qEnd = faq.findIndex((item, index) => index > qs && headingBlock === item.nodeType)
  }

  result.push({
    question: extractText(faq[qStart]),
    answer: documentToHtmlString(document(faq.slice(qStart + 1))),
  })

  //console.log(result)
  return result
}

//
// we need to recursively extract text [nodes] from a rich text object
// for the schema faq that only accepts text in the "Question: { name }"
//
const extractText = (node, initialText = '') => {
  if (!node) return null
  let text = initialText

  node.content.forEach(item => {
    if (item.nodeType === 'text') {
      text += item.value
    } else {
      text += extractText(item, text)
    }
  })

  return text
}

const EmbeddedVideo = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 */
  overflow: hidden;
  width: 100%;
  margin-bottom: 2em;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

const LazyIframe = ({ url, title }) => {
  const containerRef = useRef()
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting) {
    lockRef.current = true
  }
  return (
    <EmbeddedVideo ref={containerRef}>
      {lockRef.current && (
        <iframe
          title={title}
          src={url}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowfullscreen"
        />
      )}
    </EmbeddedVideo>
  )
}

//
// Contentful RichText to React rendered for the main article/page content
// for TOCs, there's tocOptions
// – processing embedded elements
// - styling
//
const renderOptions2 = content => {
  return {
    blockquote: node => {
      return <blockquote>{node.content}</blockquote>
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 id={`${slugify(children)}`}>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 id={`${slugify(children)}`}>{children}</h3>
      },
      [BLOCKS.EMBEDDED_ASSET]: embedded => {
        const { data } = embedded
        const imageID = data.target.sys.id

        const { gatsbyImageData, title } = content.references.find(({ contentful_id: id }) => id === imageID)

        const image = getImage(gatsbyImageData)

        return <GatsbyImage style={{ marginBottom: '2em' }} image={image} alt={title} title={title} />
      },
      [BLOCKS.EMBEDDED_ENTRY]: embedded => {
        const { data } = embedded
        const entryID = data.target.sys.id
        const entryData = content.references.find(({ contentful_id: id }) => id === entryID)

        switch (entryData.__typename) {
          case 'ContentfulYoutubeEmbed':
            if (!entryData.videoId) return null // break if there's an error in embed
            return (
              <>
                <Script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{ __html: schemaOrgVideo(entryData.videoId) }}
                />
                <LazyIframe
                  title={entryData.videoId.title}
                  url={`https://www.youtube.com/embed/${entryData.videoId.videoId}?modestbranding=1&cc_load_policy=1&rel=0`}
                />
              </>
            )
          default:
            return null
        }
      },
    },
  }
}

//
// The main article/page content renderer component
// TODO: "dark" parameter is not used anywhere now. The idea was to use it for white-on-black pages/texts
//
export const RichText2 = ({ content, includeToc = false, nopadding = false, dark = false, ...props }) => {
  const toc = includeToc && TableOfContents(content.raw)
  const { preToc, postToc } = sliceContentAtFirstH2(content.raw)

  const faqSection = extractFaqSection(content.raw)
  const faqArray = normaliseFaqSection(faqSection)
  const richFaq = faqArray && schemaOrgFaq(faqArray)
  const FAQ = richFaq && <Script type="application/ld+json" dangerouslySetInnerHTML={{ __html: richFaq }} />

  return (
    <>
      {FAQ}
      <RichText nopadding={nopadding} {...props}>
        {preToc && documentToReactComponents(preToc, renderOptions2(content))}
        {toc}
        {postToc && documentToReactComponents(postToc, renderOptions2(content))}
      </RichText>
    </>
  )
}

const RichSnippetSection = styled.section`
  font-size: 16px;
  line-height: 150%;
  & ul {
    margin-left: 16px;
    list-style-type: disc;
    li {
      margin-bottom: 4px;
      :last-of-type {
        margin-bottom: 24px;
      }
    }
  }
  & p {
    :not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
  & h3 {
    font-weight: 700;
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  & h4 {
    font-weight: 700;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  & table {
    width: 100%;
    margin-bottom: 16px;
    p {
      margin: 0;
    }
    th,
    td {
      padding: 8px 16px;
    }
    th {
      background-color: black;
      color: white;
      border: 1px solid black;
    }
    td {
      border: 1px solid grey;
    }
  }
`

//
// ToDo: rework like RichCase to accept className
//
export const RichSnippet = ({ content, style }) => {
  if (!content) return null
  const rewrapped = rewrap(content.raw)
  return (
    <RichSnippetSection style={style}>
      {rewrapped && documentToReactComponents(rewrapped, renderOptions2(content))}
    </RichSnippetSection>
  )
}

export const RichCase = ({ content, className }) => {
  const rewrapped = rewrap(content.raw)
  return <section className={className}>{rewrapped && documentToReactComponents(rewrapped)}</section>
}

const rewrap = json => {
  const { content } = JSON.parse(json)
  return document(content)
}

export default RichText2
