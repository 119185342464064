import React from 'react'
import { styled } from '@linaria/react'
import { formFields, fieldInTheForm } from '../formState'
import { Input } from './Input'

export const InputsGroup = () => {
  return (
    <Container>
      {fieldInTheForm.map(fieldName => (
        <Input key={fieldName} fieldData={formFields[fieldName]} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 24px;
`
