import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { AspectRatioContainer, Wrapper, TextLink } from '@/UI'
import { HighlightType } from '../types'
import { HighlightGatsbyImage } from '@/blocks/highlights'

interface Props {
  data: HighlightType
}

export const HighlightMain = ({ data }: Props) => {
  const { bigIcon, title, subtitle, url } = data

  return (
    <Link to={url}>
      <Root>
        <ContentWrapper $noMobilePadding={false}>
          <TextsBlock>
            <Title>{title}</Title>
            <TextLink $size="big">{subtitle}</TextLink>
          </TextsBlock>
          <ImageBlock>
            <HighlightGatsbyImage alt={title} image={bigIcon.gatsbyImageData} />
          </ImageBlock>
        </ContentWrapper>
      </Root>
    </Link>
  )
}

const Root = styled.div`
  display: flex;
  padding: 32px 0;
  background: linear-gradient(
    180deg,
    #1f1c1c 0%,
    ${colors.secondary.secondary} 23.23%,
    ${colors.secondary.secondary} 100%
  );
`

const ContentWrapper = styled(Wrapper)`
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  grid-gap: 32px;

  ${desktopSmallMedia} {
    grid-template-columns: auto 416px;
    grid-gap: 96px;
  }
`

const ImageBlock = styled(AspectRatioContainer)`
  width: 100%;
  max-width: 392px;
  padding: 0 8px;
  
  ${desktopSmallMedia} {
    padding: unset;
  }
`

const TextsBlock = styled.div`
  display: grid;
  grid-gap: 24px;
  order: 2;
  justify-items: center;

  ${desktopSmallMedia} {
    order: 0;
    justify-items: left;
  }
`

const Title = styled.h2`
  color: #fff;
  font-family: ${fontLeagueSpartan};
  font-size: 36px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.36px;
  text-align: center;

  ${desktopSmallMedia} {
    text-align: left;
    font-size: 76px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    letter-spacing: -1.14px;
  }
`
