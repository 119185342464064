import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { TopInfo, VideoGrid } from './components'
import { PageHeading, Envelope } from '~/features/insights/common'

interface Props {
  pageTitle: string
}

export const Video = ({ pageTitle }: Props) => {
  const data = useStaticQuery<Queries.VideosQueryQuery>(graphql`
    query VideosQuery {
      selectedYoutubeVideos: youtubePlaylist(originalID: { eq: "PLrzGKxp1pvT_Q0UDDbfARhaFf-Cam7ZOS" }) {
        id
        videos {
          title
          videoId
          publishedAt(formatString: "DD MMMM YYYY", locale: "en-gb")
          localThumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 10)
            }
          }
        }
      }
    }
  `)

  const videos = (data?.selectedYoutubeVideos?.videos ?? []).filter(Boolean)

  if (!videos.length) return null

  return (
    <Envelope>
      <PageHeading title={pageTitle}>
        <TopInfo videosCount={videos.length} />
      </PageHeading>
      <VideoGrid videos={videos} />
    </Envelope>
  )
}
