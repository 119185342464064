import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { desktopSmallMedia } from '@/lib/theme'
import { theme } from '../gatsby-plugin-theme-ui/index'

const CardGrid = styled.div`
  align-self: start;
  padding: 1em;
  max-width: 72em;
  justify-self: center;
  display: grid;
  grid-auto-flow: dense;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  gap: 1em;
  ${desktopSmallMedia} {
    grid-template-columns: repeat(${props => Math.min(4, props.numLocations)}, 1fr);
  }
`
const OneCard = styled.div`
  border-radius: 0.3em;
  box-shadow: 0 0 3em -1em ${theme.colors.pale};
  background-color: ${theme.colors.pale};
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 0 3em -1em ${theme.colors.deep};
  }
  // for stretched links
  position: relative;
  display: grid;
  gap: 1em;
  padding: 2em;
  &:hover h3,
  &:hover a {
    color: ${theme.colors.secondary};
  }
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-template-rows: repeat(auto-fit, minmax(100px, min-content));
`

const CardBody = styled.div`
  background-color: inherit;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(1.5em, min-content));
  gap: 1em;
`

const Name = styled.h3`
  padding: 0;
  margin: 0;
  color: black;
  font-size: 1.5em;
  font-weight: 700;
  transition: 0.3s all;
`

const addressCss = css`
  padding: 0;
  margin: 0;
  color: black;
  font-size: 1em;
  line-height: ${theme.lineHeights.body};
`

const Address = ({ address }) => {
  return (
    <div className={addressCss}>
      <div>{address.streetAddress}</div>
      <div>{address.addressLocality}</div>
      <div>{address.addressRegion}</div>
      <div>{address.postalCode}</div>
      <div>{address.addressCountry}</div>
    </div>
  )
}

const LinkedPhone = styled.a`
  color: black;
  text-decoration: none;
  font-weight: 700;
  font-size: 1em;
  line-height: ${theme.lineHeights.body};
  padding: 0;
  margin: 0;
  transition: all 0.3s;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
  }
`

const LocationsGrid = ({ locations, ...props }) => {
  const numLocations = locations.length

  return (
    <CardGrid numLocations={numLocations} {...props}>
      {locations.map(node => {
        return (
          <OneCard key={node.id}>
            <CardBody>
              <Name>{node.locationName}</Name>
              <Address address={node} />
              <LinkedPhone href={`tel:${node.telephone}`}>{`${node.telephone}`}</LinkedPhone>
            </CardBody>
          </OneCard>
        )
      })}
    </CardGrid>
  )
}

export default LocationsGrid
