import React from 'react'
import { styled } from '@linaria/react'
import { InputsGroup, NewsletterConsent, PrivacyAgreement, Submit } from './components'

export const FormContent = () => {
  return (
    <Container>
      <InputsGroup />
      <AgreementGroup>
        <PrivacyAgreement />
        <NewsletterConsent />
      </AgreementGroup>
      <Submit />
    </Container>
  )
}

const Container = styled.form`
  display: grid;
  grid-row-gap: 32px;
`

const AgreementGroup = styled.div`
  display: grid;
  grid-row-gap: 16px;
`
