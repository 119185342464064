import React, { ReactNode, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { styled } from '@linaria/react'
import { use100vh } from 'react-div-100vh'

interface Props {
  zIndex?: number
  children: ReactNode
}

export const Portal = ({ children, zIndex = 1000 }: Props) => {
  const [modalRoot, setModalRoot] = useState(null)
  const portalHeight = use100vh()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!modalRoot) {
      const div = document.createElement('div')
      div.classList.add('modal-root')
      document.body.appendChild(div)
      setModalRoot(div)
    } else {
      return () => {
        document.body.removeChild(modalRoot)
      }
    }
  }, [modalRoot])

  return (
    modalRoot &&
    ReactDOM.createPortal(
      <Container $zIndex={zIndex} $height={portalHeight}>
        {children}
      </Container>,
      modalRoot,
    )
  )
}

const Container = styled.div<{ $zIndex: number; $height: number }>`
  --portal-height: ${({ $height }) => $height}px;
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex};
`
