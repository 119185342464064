import React from 'react'
import { Link, graphql } from 'gatsby'
import { BgImage } from 'gbimage-bridge'
import Pluralize from 'react-pluralize'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { useLocation } from '@reach/router'
import { LightHero, Title, HeroExtras } from '../components/hero'
import { RichText2 } from '../components/richtext'
import AuthorGrid from '../components/authorgrid'
import ContactForm from '../components/contactform-new'
import { schemaOrgOrganizationShortObject } from '@/utils/schema.org'
import { tagState } from '~/features/insights/blog'
import { tabletMedia, desktopSmallMedia, desktopLargeMedia } from '@/lib/theme'
import { theme } from '../gatsby-plugin-theme-ui/index'
import { SEO, Layout } from '@/blocks/layout'

const PostExtras = styled.aside`
  padding: 1em;
  margin: 0;
  max-width: 80em;
  justify-self: center;
  align-self: start;
  grid-area: extras;
  display: grid;
  gap: 1em;
  grid-template: 'authors' 'tags' 'tag' 'readnext';
  ${tabletMedia} {
    padding: 2em;
    gap: 2em;
    grid-template: 'authors authors' 'tags readnext' 'tag tag';
  }
  ${desktopSmallMedia} {
    grid-template: 'authors authors authors' 'tags tag readnext';
  }
  ${desktopLargeMedia} {
    grid-template-columns: 'authors' 'tags' 'tag' 'readnext';
  }
`

const OneExtraWrapper = styled.div`
  grid-area: ${props => props.gridArea};
  border-radius: 0.3em;
  box-shadow: 0 0 3em -1em ${theme.colors.pale};
  background-color: black;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 0 3em -1em ${theme.colors.deep};
  }
  &:hover h3.highlightOnHover {
    color: ${theme.colors.secondary};
  }
  // for stretched links
  position: relative;
  display: grid;
  gap: 0;
  padding: 0;
  grid-template-rows: [top image-top] 2em [header-top] min-content [header-bottom] 2em [content-top] 1fr [content-bottom] 2em [bottom image-bottom];
  grid-template-columns: [start image-start] 2em [content-start] 1fr [content-end] 2em [end image-end];

  .bgimage {
    border-radius: inherit;
    overflow: hidden;
    grid-area: image-top/image-start/image-bottom/image-end;
    z-index: 0;
  }

  & h2.title {
    padding: 0;
    margin: 0;
    grid-area: header-top/content-start/header-bottom/content-end;
    color: white;
    z-index: 1;
    border-bottom: 1px solid ${theme.colors.pale};
    font-weight: 500;
  }

  & div.contents {
    padding: 0;
    margin: 0;
    z-index: 1;
    grid-area: content-top/content-start/content-bottom/content-end;
    align-self: start;
    color: white;
  }
`

const OneExtra = ({ title, background, children, gridArea }) => {
  const backgroundFluidImageStack = [background, `radial-gradient(rgb(0,0,0,.7), rgb(0,0,0,1))`].reverse()

  return (
    <OneExtraWrapper gridArea={gridArea}>
      <BgImage image={backgroundFluidImageStack} className="bgimage" />
      <h2 className="title">{title}</h2>
      <div className="contents">{children}</div>
    </OneExtraWrapper>
  )
}

const PostTagsWrapper = styled.div`
  margin-bottom: 2em;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover h3 {
    color: ${theme.colors.secondary};
  }
  & a {
    text-decoration: none;
  }
  & h3 {
    transition: all 0.3s;
  }
  & small {
    display: block;
    font-style: italic;
    font-size: 0.9em;
    color: ${theme.colors.pale};
  }
`

const PostTags = ({ tags, image }) => {
  return tags ? (
    <OneExtra title="Tags" background={image.fluid} gridArea="tags">
      {tags.map((tag, index) => {
        if (tag.totalPosts > 1 || index === 0) {
          return (
            <PostTagsWrapper key={tag.id}>
              <Link
                to="/blog/"
                onClick={() => {
                  tagState.selectedTag = tag.tag
                }}
              >
                <h3>{tag.tag}</h3>
                <small>
                  <Pluralize singular="article" count={tag.totalPosts} />
                </small>
              </Link>
            </PostTagsWrapper>
          )
        }
        return null
      })}
    </OneExtra>
  ) : null
}

const TopTag = ({ tags, image, currentpostid }) => {
  let topTag

  if (!tags) return null

  tags.map((tag, index) => {
    if (tag.totalPosts > 1 || index === 0) {
      if (!topTag) {
        topTag = tag
      } else if (topTag.totalPosts === 1 && tag.totalPosts > 1) {
        topTag = tag
      }
    }
    return null
  })

  return (
    <OneExtra title={topTag.tag} background={topTag.image ? topTag.image.fluid : image.fluid} gridArea="tag">
      {topTag.post
        .filter(post => post.id !== currentpostid)
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.timestamp < b.timestamp ? 1 : b.timestamp < a.timestamp ? -1 : 0))
        .slice(0, 5)
        .map(post => {
          return (
            <PostTagsWrapper key={post.id}>
              <Link to={`/${post.slug}/`}>
                <h3>{post.title}</h3>
                <small>{post.datetime}</small>
              </Link>
            </PostTagsWrapper>
          )
        })}
    </OneExtra>
  )
}

const ReadNextTitle = styled.h3`
  padding: 0;
  margin: 0 0 1em 0;
  color: white;
  text-shadow: 0 0 3px black;
  font-size: 1.5em;
  font-weight: 700;
  line-height: ${theme.lineHeights.heading};
  transition: 0.3s all;
`

const linkedExcerpt = css`
  color: white;
  text-shadow: 0 0 3px black;
  text-decoration: none;
  font-size: 1em;
  line-height: ${theme.lineHeights.body};
  padding: 0;
  margin: 0;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
  }
`

const LinkedExcerpt = ({ children, ...props }) => {
  return (
    <Link {...props} className={linkedExcerpt}>
      {children}
    </Link>
  )
}

const ReadNext = ({ previous, next, defaultFluidBg }) => {
  const readNext = next || previous

  return (
    <OneExtra title="Read next" background={readNext.image ? readNext.image.fluid : defaultFluidBg} gridArea="readnext">
      <ReadNextTitle className="highlightOnHover">{readNext.title}</ReadNextTitle>
      <LinkedExcerpt
        to={`/${readNext.slug}/`}
        dangerouslySetInnerHTML={{
          __html: readNext.excerpt.excerpt,
        }}
      />
    </OneExtra>
  )
}

const BlogPostContentfulTemplate = ({ data, pageContext }) => {
  const post = data.contentfulPost
  const { previous, next } = pageContext
  const { authors, tags } = post
  const numAuthors = authors.length
  const currentPostId = post.id
  const defaultTagImage = data.contentfulDefaultImage.image

  return (
    <Layout>
      <div
        style={{
          display: 'grid',
          gridTemplate: "'hero' 'content' 'form' 'extras'",
          gridTemplateColumns: '100%',
        }}
      >
        <LightHero style={{ gridArea: 'hero' }}>
          <Title>{post.title}</Title>
          <HeroExtras>
            {post.authors.map(author => {
              return (
                <div key={author.slug}>
                  <Link to={`/team/${author.slug}/`}>{author.name}</Link>
                </div>
              )
            })}
            <div>{post.datetime ? post.datetime : 'New'}</div>
            {post.tags
              ? post.tags.slice(0, 1).map(tag => {
                  return (
                    <div key={tag.slug}>
                      <Link
                        to="/blog/"
                        onClick={() => {
                          tagState.selectedTag = tag.tag
                        }}
                      >
                        {tag.tag}
                      </Link>
                    </div>
                  )
                })
              : null}
          </HeroExtras>
        </LightHero>
        <RichText2 style={{ maxWidth: '48em', gridArea: 'content' }} content={post.content} includeToc />
        <PostExtras>
          <AuthorGrid
            authors={authors}
            style={{
              gridArea: 'authors',
              justifySelf: 'center',
              maxWidth: theme.breakpoints[Math.min(4, numAuthors)],
              margin: 0,
              padding: 0,
            }}
          />
          <PostTags tags={tags} image={defaultTagImage} />
          <TopTag tags={tags} image={defaultTagImage} currentpostid={currentPostId} />
          <ReadNext previous={previous} next={next} defaultFluidBg={defaultTagImage.fluid} />
        </PostExtras>
        <EmbeddedForm>
          <ContactForm />
        </EmbeddedForm>
      </div>
    </Layout>
  )
}

const EmbeddedForm = styled.div`
  width: 90%;
  max-width: 40em;
  grid-area: form;
  margin: 0 2em 3em 2em;
  place-self: start center;
`

export default BlogPostContentfulTemplate

export const Head = ({ data }) => {
  const location = useLocation()
  const post = data.contentfulPost
  const heroImage = post.image ? post.image : data.contentfulDefaultImage.image
  const seoImage = heroImage.fixed.images.fallback.src
  const { authors } = post

  const richResultAuthors = authors.map(author => {
    return {
      '@type': 'Person',
      name: author.name,
      url: `https://samexpert.com/team/${author.slug}/`,
    }
  })

  const richResultObject = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: post.title,
    image: seoImage,
    datePublished: post.createdAt,
    dateModified: post.updatedAt,
    author: richResultAuthors,
    publisher: schemaOrgOrganizationShortObject(data.site.siteMetadata),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${data.site.siteMetadata.siteUrl}${location.pathname}`,
    },
  }

  const richresult = JSON.stringify(richResultObject)

  return <SEO title={post.title} description={post.excerpt.excerpt} image={seoImage} richresult={richresult} />
}

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      excerpt {
        excerpt
      }
      datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
      createdAt
      updatedAt
      content {
        raw
        references {
          ... on ContentfulAsset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            contentful_id
            title
          }
          ... on ContentfulYoutubeEmbed {
            contentful_id
            __typename
            videoId {
              videoId
              title
              description
              publishedAt
              duration
              likeCount
              viewCount
              commentCount
              thumbnail {
                url
              }
            }
            title
          }
        }
      }
      image {
        fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
        fixed: gatsbyImageData(width: 1200, height: 630, formats: [AUTO, WEBP], quality: 20)
      }
      tags {
        tag
        id
        slug
        totalPosts
        post {
          id
          timestamp: datetime
          datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
          title
          slug
        }
        image {
          fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      authors {
        name
        slug
        id
        linkedIn
        twitter  
        bio {
          bio
          childMarkdownRemark {
            html
          }
        }
        avatar {
          gatsbyImageData(width: 100, layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 50)
        }
        miniavatar: avatar {
          gatsbyImageData(width: 32, layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 50)
        }
      }
    }
    contentfulDefaultImage(for: { eq: "Article" }) {
      image {
        fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
        fixed: gatsbyImageData(width: 1200, height: 630, formats: [AUTO, WEBP], quality: 20)
      }
    }
  }
`
