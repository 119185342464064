import React from 'react'
import { styled } from '@linaria/react'

const icons = {
  Telegram,
  Youtube,
  Twitter,
  LinkedIn,
}

type IconsType = keyof typeof icons

interface Props {
  iconSize?: number
  icon: IconsType
  className?: string
  tag?: 'div' | 'span'
}

export const Icon = ({ icon, className, tag = 'div', iconSize = 36 }: Props) => {
  const CurrentElement = icons[icon]

  return (
    <Container as={tag} iconSize={iconSize} className={className}>
      <svg viewBox="0 0 36 36" fill="currentColor" width={iconSize} height={iconSize}>
        <CurrentElement />
      </svg>
    </Container>
  )
}

const Container = styled.div<{ iconSize: number }>`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  width: ${({ iconSize }) => iconSize}px;
  height: ${({ iconSize }) => iconSize}px;
`

function Telegram() {
  return (
    <path d="m18 0c-9.941 0-18 8.059-18 18s8.059 18 18 18 18-8.059 18-18-8.059-18-18-18zm8.343 12.241c-.271 2.846-1.443 9.753-2.04 12.941-.252 1.349-.749 1.801-1.23 1.845-1.045.096-1.839-.691-2.851-1.354-1.584-1.038-2.479-1.685-4.017-2.698-1.777-1.171-.625-1.815.388-2.867.265-.275 4.871-4.464 4.96-4.844.011-.048.022-.225-.084-.318s-.261-.062-.373-.036c-.159.036-2.69 1.709-7.593 5.018-.718.493-1.369.734-1.952.721-.643-.014-1.879-.363-2.798-.662-1.127-.366-2.023-.56-1.945-1.182.041-.324.487-.656 1.339-.994 5.247-2.286 8.746-3.793 10.497-4.522 4.999-2.079 6.037-2.44 6.715-2.452.149-.002.482.034.697.209.143.125.235.299.256.487.038.234.048.472.031.708z" />
  )
}

function Youtube() {
  return (
    <path d="m35.248 9.304c-.414-1.559-1.634-2.787-3.183-3.203-2.807-.757-14.065-.757-14.065-.757s-11.257 0-14.065.757c-1.549.417-2.769 1.644-3.183 3.203-.752 2.826-.752 8.722-.752 8.722s0 5.896.752 8.721c.414 1.559 1.634 2.736 3.183 3.152 2.808.757 14.065.757 14.065.757s11.257 0 14.065-.757c1.549-.417 2.769-1.593 3.183-3.152.752-2.826.752-8.721.752-8.721s0-5.896-.752-8.722zm-20.93 14.074v-10.705l9.409 5.353z" />
  )
}

function LinkedIn() {
  return (
    <path d="m33.429 0h-30.866c-1.414 0-2.563 1.165-2.563 2.596v30.809c0 1.43 1.149 2.595 2.563 2.595h30.865c1.415 0 2.572-1.165 2.572-2.596v-30.808c0-1.431-1.157-2.596-2.571-2.596zm-22.549 30.857h-5.335v-17.18h5.344v17.18zm-2.667-19.527c-1.712 0-3.094-1.39-3.094-3.094s1.382-3.094 3.094-3.094c1.704 0 3.094 1.39 3.094 3.094-.001 1.712-1.383 3.094-3.094 3.094zm22.668 19.527h-5.336v-8.357c0-1.993-.04-4.556-2.772-4.556-2.78 0-3.206 2.17-3.206 4.412v8.502h-5.336v-17.18h5.119v2.346h.072c.715-1.35 2.459-2.772 5.054-2.772 5.4 0 6.404 3.56 6.404 8.188v9.417z" />
  )
}

function Twitter() {
  return (
    <path d="m32.3 10.727c.023.317.023.634.023.952 0 9.674-7.424 20.821-20.992 20.821-4.18 0-8.063-1.201-11.33-3.285.594.068 1.165.091 1.782.091 3.449 0 6.624-1.155 9.16-3.127-3.244-.068-5.962-2.175-6.899-5.075.457.068.914.113 1.393.113.662 0 1.325-.091 1.942-.249-3.381-.68-5.916-3.625-5.916-7.182v-.091c.982.544 2.124.884 3.335.929-1.988-1.314-3.29-3.557-3.29-6.094 0-1.359.365-2.605 1.005-3.693 3.632 4.441 9.091 7.341 15.213 7.658-.114-.544-.183-1.11-.183-1.677 0-4.033 3.289-7.318 7.378-7.318 2.124 0 4.043.884 5.391 2.311 1.667-.317 3.266-.929 4.683-1.767-.548 1.699-1.713 3.127-3.244 4.033 1.485-.159 2.924-.567 4.249-1.133-1.005 1.45-2.261 2.741-3.7 3.783z" />
  )
}
