import React, { Fragment } from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { graphql, navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Flickity from 'react-flickity-component'
import '../flickity/flickity.css'
import { theme } from '../gatsby-plugin-theme-ui/index'
import { RichSnippet, RichCase } from '../components/richtext'
import { tabletMedia, desktopSmallMedia, desktopLargeMedia } from '@/lib/theme'
import ContactForm from '~/components/contactform-new'
import LogoScroller2 from '~/components/logoscroller2'
import SectionWithHighlight from '~/components/service/sectionwithhighlight'
import Highlight from '~/components/service/highlight'
import Steps from '~/components/service/steps'
import { SEO, Layout } from '@/blocks/layout'
 
const TestimonialsWrapper = styled.div`
  grid-area: testimonials;
  display: grid;
  align-items: center;
  background: #ededed;
  padding: 24px 24px 48px 24px;
  ${tabletMedia} {
    padding: 48px;
  }
`

const OneTestimonial = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 120px;
  margin-right: 24px;
  display: grid;
  grid-template: 'name' 'company' 'text';
  justify-items: start;
  ${desktopSmallMedia} {
    grid-template: 'name company' 'text text';
    grid-template-columns: min-content auto;
  }
`

const TestimonialName = styled.div`
  grid-area: name;
  padding: 3px 16px;
  background: #272424;
  opacity: 0.6;
  border-radius: 0px;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
`

const TestimonialCompany = styled.div`
  grid-area: company;
  padding: 3px 16px;
  background: black;
  border-radius: 0px;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
`

const TestimonialText = styled.div`
  grid-area: text;
  padding: 36px 24px 0 24px;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  position: relative;
  width: 100%;
  ::before {
    position: absolute;
    content: '“';
    transform: translate(-32px, -8px);
    color: #272424;
    opacity: 0.6;
    font-size: 80px;
  }
  ${desktopSmallMedia} {
    padding: 24px 24px 24px 40px;
    ::before {
      transform: translate(-52px, 16px);
    }
  }
`

const Testimonials = ({ testimonials }) => {
  //console.log(testimonials)
  return (
    <TestimonialsWrapper>
      <Flickity
        options={{
          prevNextButtons: false,
          wrapAround: true,
          autoPlay: 14000,
        }}
      >
        {testimonials.map(item => {
          return (
            <OneTestimonial key={item.contentful_id}>
              <TestimonialName>{item.name}</TestimonialName>
              <TestimonialCompany>{item.company}</TestimonialCompany>
              <TestimonialText>{item.text.text}</TestimonialText>
            </OneTestimonial>
          )
        })}
      </Flickity>
    </TestimonialsWrapper>
  )
}

//
// MAIN page component
//
const PageTemplate = ({ data }) => {
  const service = data.contentfulService

  return (
    <Layout>
      <PageWrapper>
        <Hero>
          <Title>{service.title}</Title>
          <ContactButton id="contact">
            <form
              onSubmit={event => {
                event.preventDefault()
                navigate('#contactform')
              }}
            >
              <button type="submit">Contact us</button>
            </form>
          </ContactButton>
        </Hero>
        <ProblemsAndSolutions>
          <Problems>
            <InnerContainer>
              <H2>{service.problemsHeading}</H2>
              <RichSnippet content={service.problemsBody} />
            </InnerContainer>
          </Problems>
          <Solutions>
            <InnerContainer>
              <H2>{service.solutionHeading}</H2>
              <RichSnippet content={service.solutionBody} />
            </InnerContainer>
          </Solutions>
        </ProblemsAndSolutions>
        <SectionWithHighlight style={{ gridArea: 'howwework' }}>
          <H2 style={{ gridArea: 'heading' }}>{service.howWeWorkHeading}</H2>
          <RichSnippet style={{ gridArea: 'body' }} content={service.howWeWorkBody} />
          <Highlight style={{ gridArea: 'highlight' }}>{service.howWeWorkHighlight}</Highlight>
        </SectionWithHighlight>
        {service.steps && <Steps steps={service.steps} />}
        {service.whyWorkWithUs && <USPs usps={service.whyWorkWithUs} />}
        <SectionWithHighlight style={{ gridArea: 'whyworkwithus' }}>
          <H2 style={{ gridArea: 'heading' }}>{service.whyWorkWithUsHeading}</H2>
          <RichSnippet style={{ gridArea: 'body' }} content={service.whyWorkWithUsBody} />
          <Highlight style={{ gridArea: 'highlight' }}>{service.whyWorkWithUsHighlight}</Highlight>
        </SectionWithHighlight>
        <FormSection id="contactform">
          <H2 className={contactus}>{service.contactUsHeading}</H2>
          <ContactForm className={contactform} />
          {service.testimonials && <Testimonials testimonials={service.testimonials} />}
        </FormSection>
        {service.logos && <LogoScroller2 logos={service.logos} gridArea='clients'/>}
        {service.cases && (
          <>
            <CasesHeading>
              <H2>{service.casesHeading}</H2>
            </CasesHeading>
            <Cases cases={service.cases} /> )
          </>
        )}
      </PageWrapper>
    </Layout>
  )
}

const H2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
`

const CasesHeading = styled.div`
  grid-area: casesheading;
  padding: 32px 24px 24px 24px;
  width: 100%;
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`

const CasesWrapper = styled.div`
  grid-area: cases;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  ${desktopLargeMedia} {
    width: 100%;
    max-width: 1440px;
    justify-self: center;
  }
`

const CaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cases}, 1fr);
  grid-template-rows: 1fr;
  gap: 24px;
  padding: 0 24px 48px 24px;

  overflow-x: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  scroll-behavior: smooth;
`

const SingleCase = styled.div`
  background: #dbd8d4;
  padding: 24px;
  min-width: 294px;
  max-width: 75vw;
  white-space: normal;
`

const CaseTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding-bottom: 24px;
  color: #272424;
`

const caseclass = css`
  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #272424;
    padding-bottom: 8px;
    ::after {
      content: ':';
    }
    :not(:first-of-type) {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px dashed rgba(0, 0, 0, 0.4);
      ${desktopLargeMedia} {
        margin-top: -8px;
      }
    }
  }
  ul {
    margin-left: 16px;
    list-style-type: disc;
    li {
      margin: 0;
    }
  }
  ${desktopLargeMedia} {
    *:not(h4, li, li p) {
      transform: translateY(-32px);
      margin-left: 112px;
      //position: relative;
      //top: -32px;
    }
    ul {
      padding-left: 16px;
    }
  }
`

const Cases = ({ cases }) => {
  return (
    <CasesWrapper>
      <CaseGrid cases={cases.length}>
        {cases.map(item => {
          return (
            <SingleCase key={item.contentful_id}>
              <CaseTitle>{item.title}</CaseTitle>
              <RichCase content={item.content} className={caseclass} />
            </SingleCase>
          )
        })}
      </CaseGrid>
    </CasesWrapper>
  )
}

const USPGrid = styled.div`
  grid-area: usps;
  display: grid;
  width: 100%;
  grid-template-rows: repeat(${props => props.usps}, 1fr);
  grid-template-columns: 1fr;
  padding: 32px 0;
  ${tabletMedia} {
    padding: 32px 24px;
  }
  ${desktopSmallMedia} {
    grid-template-columns: repeat(${props => props.usps}, 1fr);
    grid-template-rows: 1fr;
  }
  ${desktopLargeMedia} {
    width: 100%;
    max-width: 1440px;
    justify-self: center;
  }
`

const SingleUSP = styled.div`
  display: grid;
  grid-template: 'title' 'content';
  grid-template-rows: max-content auto;
  padding: 24px;
  gap: 24px;
  background-color: #ededed;
  :nth-child(even) {
    background-color: #e3e3e3;
  }
  ${desktopSmallMedia} {
    padding: 48px;
  }
`

const USPs = ({ usps }) => {
  return (
    <USPGrid usps={usps.length}>
      {usps.map(item => {
        return (
          <SingleUSP key={item.contentful_id}>
            <H2 style={{ gridArea: 'title' }}>{item.title}</H2>
            <RichSnippet style={{ gridArea: 'content' }} content={item.content} />
          </SingleUSP>
        )
      })}
    </USPGrid>
  )
}

export default PageTemplate

export const Head = ({ data }) => {
  const service = data.contentfulService
  const seoImageExists = !!service.seoImage
  const seoImage = seoImageExists ? getImage(service.seoImage.fixed).images.fallback.src : undefined

  return <SEO title={service.title} description={service.seoDescription} image={seoImage} />
}

export const pageQuery = graphql`
  query ContentfulServiceBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulService(serviceId: { eq: $slug }) {
      title: serviceName
      seoDescription
      problemsHeading
      problemsBody {
        raw
      }
      solutionHeading
      solutionBody {
        raw
      }
      howWeWorkHeading
      howWeWorkBody {
        raw
        references {
          ... on ContentfulAsset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            contentful_id
            title
          }
        }
      }
      howWeWorkHighlight
      steps {
        ... on ContentfulServiceStep {
          name
          subtitle
          substeps
          contentful_id
        }
      }
      whyWorkWithUs {
        contentful_id
        title
        content {
          raw
        }
      }
      whyWorkWithUsHeading
      whyWorkWithUsBody {
        raw
      }
      whyWorkWithUsHighlight
      testimonials {
        name
        company
        shortTitle
        text {
          text
        }
        contentful_id
      }
      logos {
        id
        contentfulid
        okOnCompanyPage
        order
        logo {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            height: 90
            resizingBehavior: SCALE
            layout: FIXED
          )
        }
      }
      casesHeading
      cases {
        title
        content {
          raw
        }
        contentful_id
      }
      seoImage {
        fixed: gatsbyImageData(width: 1200, height: 630, layout: FIXED, formats: [AUTO], quality: 10)
      }
      contactUsHeading
    }
  }
`

const PageWrapper = styled.div`
  display: grid;
  grid-template: 'hero' 'probsol' 'howwework' 'steps' 'usps' 'whyworkwithus' 'form' 'clients' 'casesheading' 'cases';
`

const Hero = styled.div`
  display: grid;
  grid-area: hero;
  grid-template: 'title' 'button';
  width: 100%;
  padding: 24px;
  ${tabletMedia} {
    grid-template: 'title button';
    grid-template-columns: 2fr 1fr;
    gap: 48px;
  }
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`

const Title = styled.h1`
  grid-area: title;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 116%;
  letter-spacing: -0.02em;
  margin: 42px 0;
  ${tabletMedia} {
    font-size: 44px;
  }
  ${desktopSmallMedia} {
    font-size: 68px;
  }
  ${desktopLargeMedia} {
    font-size: 78px;
  }
`

const ContactButton = styled.nav`
  grid-area: button;
  width: 100%;
  & button {
    background-color: #272424;
    border-radius: 4px;
    color: white;
    padding: 16px;
    margin: 24px 0;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 113%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  & button:hover {
    background-color: ${theme.colors.logoOrange};
    cursor: pointer;
  }
  ${tabletMedia} {
    align-self: center;
    justify-self: left;
    & button {
      padding: 24px;
      max-width: 264px;
    }
  }
`

const ProblemsAndSolutions = styled.div`
  display: grid;
  grid-area: probsol;
  grid-template: 'problems' 'solutions';
  width: 100%;
  margin-bottom: 32px;
  ${tabletMedia} {
    grid-template: 'left problems right' 'left solutions right';
    grid-template-columns: 24px auto 24px;
  }
  ${desktopSmallMedia} {
    grid-template: 'left problems solutions right';
    grid-template-columns: 24px auto auto 24px;
  }
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`

const Problems = styled.div`
  background: #ededed;
  grid-area: problems;
  display: grid;
  align-items: start;
`

const Solutions = styled.div`
  background: #e3e3e3;
  grid-area: solutions;
  display: grid;
  align-items: start;
`

const InnerContainer = styled.div`
  display: grid;
  grid-template: 'header' 'contents';
  padding: 48px 24px;
  gap: 32px;
  ${tabletMedia} {
    padding: 48px;
  }
`

const FormSection = styled.div`
  display: grid;
  grid-area: form;
  grid-template: 'form' 'testimonials';
  margin: 32px 0;
  width: 100%;
  ${tabletMedia} {
    grid-template: 'left form right' 'left testimonials right';
    grid-template-columns: 24px auto 24px;
  }
  ${desktopSmallMedia} {
    grid-template: 'left contactus contactus right' 'left form testimonials right';
    grid-template-columns: 24px 1fr 2fr 24px;
  }
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`

const contactform = css`
  grid-area: form;
  padding: 24px;
  background: #c7c7c7;
  & input,
  textarea {
    border: none;
  }
  & label {
    text-transform: uppercase;
    font-size: 14px;
  }
`

const contactus = css`
  display: none;
  ${desktopSmallMedia} {
    grid-area: contactus;
    display: inherit;
    padding-bottom: 24px;
  }
`
/*
const logos = css`
  padding: 32px 0;
  ${desktopLargeMedia} {
    max-width: 1440px;
    justify-self: center;
  }
`
*/