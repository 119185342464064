import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { RichText2 } from '../components/richtext'
import ContactForm from '../components/contactform-new'
import LocationGrid from '../components/locationgrid'
import { LightHero, Title } from '../components/hero'
import { schemaOrgOrganization } from '@/utils/schema.org'
import { SEO, Layout } from '@/blocks/layout'

const pageTitle = 'Get in touch with SAMexpert'
const pageDescription =
  'Use this form to send us a message, call us at the numbers published here, or contact our local offices. We are always happy to help.'

const ContactPage = ({ data }) => {
  const { snippetBefore, snippetAfter, locations } = data
  const locationsNodes = locations?.nodes ?? []

  return (
    <Layout>
      <div
        style={{
          display: 'grid',
          gridTemplate: "'hero' 'before' 'form' 'locations' 'after'",
          gridTemplateColumns: '100%',
        }}
      >
        <LightHero style={{ gridArea: 'hero' }}>
          <Title>{pageTitle}</Title>
        </LightHero>
        <RichText2 style={{ gridArea: 'before', maxWidth: '48em' }} content={snippetBefore.content} />
        <ContactForm style={{ gridArea: 'form', padding: '1em 1em 3em', justifySelf: 'center', maxWidth: '32em' }} />
        <LocationGrid
          locations={locationsNodes}
          style={{ gridArea: 'locations', justifySelf: 'center', maxWidth: '48em' }}
        />
        <RichText2 style={{ gridArea: 'after', maxWidth: '48em' }} content={snippetAfter.content} />
      </div>
    </Layout>
  )
}

export default ContactPage

export const Head = ({ data }) => {
  const defaultImage = data.contentfulDefaultImage
  const seoImage = defaultImage.image.fixed ? getImage(defaultImage.image.fixed).images.fallback.src : null

  return (
    <SEO
      title={pageTitle}
      description={pageDescription}
      image={seoImage}
      richresult={schemaOrgOrganization(data.site.siteMetadata)}
    />
  )
}

export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
    contentfulDefaultImage(for: { eq: "ContactUs" }) {
      image {
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO]
          quality: 10
        )
      }
    }
    locations: allContentfulLocation(sort: { index: ASC }) {
      nodes {
        id
        addressCountry
        addressLocality
        addressRegion
        locationName
        postalCode
        streetAddress
        telephone
      }
    }
    snippetBefore: contentfulSnippet(contentfulid: { eq: "contactpage-top" }) {
      content {
        raw
      }
    }
    snippetAfter: contentfulSnippet(contentfulid: { eq: "contactpage-bottom" }) {
      content {
        raw
      }
    }
  }
`
