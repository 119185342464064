import React from 'react'
import { useSnapshot } from 'valtio'
import { formNewsletterConsent, toggleNewsletterConsent } from '../formState'
import { AgreementBase } from './AgreementBase'

export const NewsletterConsent = () => {
  const { agree } = useSnapshot(formNewsletterConsent)

  return (
    <AgreementBase isChecked={agree} toggleChecked={toggleNewsletterConsent}>
      Subscribe to our newsletter
    </AgreementBase>
  )
}
