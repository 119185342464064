import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { colors } from '@/lib/theme'

export type AuthorType = {
  name: string
  img: IGatsbyImageData
  slug?: string
}

interface Props {
  className?: string
  authors: AuthorType[]
}

export const ArticleAuthors = ({ authors, className }: Props) => {
  if (!Array.isArray(authors) || !authors.length) return null

  const isOnlyOneAuthor = authors.length === 1
  return (
    <Container className={className}>
      {authors.map(({ img, name }, idx) => (
        <ImageContainer key={name} moveCoefficient={idx}>
          <GatsbyImage image={img} alt={name} />
        </ImageContainer>
      ))}
      {isOnlyOneAuthor && <Name>{authors[0].name}</Name>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ImageContainer = styled.div<{ moveCoefficient: number }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  filter: grayscale();
  border: 1px solid #fff;

  &:not(:first-child) {
    transform: translateX(calc(-6px * ${({ moveCoefficient }) => `${moveCoefficient}`}));
  }
`

const Name = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.004em;
  color: ${colors.tertiary.tertiary};
  margin-left: 8px;
`
