import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { hoverMedia } from '@/lib/theme'

type Services = 'facebook' | 'twitter' | 'mail' | 'linkedin'

interface Service {
  icon: ReactNode
  href: string
}

function getContent(url: string, title: string) {
  const content: Record<Services, Service> = {
    facebook: {
      icon: (
        <>
          <rect width="48" height="48" fill="#000" />
          <path
            d="m38.689 9-11.121 12.707 13.082 17.293h-10.243l-8.014-10.489-9.182 10.489h-5.09l11.893-13.596-12.546-16.404h10.5l7.253 9.589 8.379-9.589zm-4.05 26.957-18.203-24.075h-3.032l18.407 24.075h2.818z"
            fill="#fff"
          />
        </>
      ),
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    twitter: {
      icon: (
        <>
          <rect width="48" height="48" fill="#3d5a98" />
          <path
            d="m33.2005 48.0494v-18.5876h6.2378l.9328-7.2436h-7.1706v-4.6235c0-2.0969.584-3.5285 3.5894-3.5285h3.8367v-6.48927c-1.8579-.19363-3.725-.2857-5.5929-.27579-5.5239 0-9.3282 3.36626-9.3282 9.57566v5.3414h-6.2378v7.2436h6.2378v18.5876z"
            fill="#fff"
          />
        </>
      ),
      href: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
    },
    mail: {
      icon: (
        <>
          <rect width="48" height="48" fill="#9e958f" />
          <path
            d="m35.986 17.962c-.001-.004 0-.009-.001-.013-.046-.833-.38-1.612-.975-2.206-.64-.64-1.491-.993-2.396-.993h-16.725c-.905 0-1.756.353-2.396.993-.595.595-.928 1.374-.974 2.207-.001.003 0 .007-.001.01-.003.061-.018.119-.018.18v11.946c0 .905.353 1.757.993 2.396.64.641 1.491.993 2.396.993h16.724c.905 0 1.756-.353 2.396-.993s.993-1.491.993-2.396v-11.947c.001-.06-.013-.117-.016-.177zm-20.097-1.212h16.724c.371 0 .72.145.982.407.146.146.245.321.314.51l-9.658 6.438-9.658-6.438c.068-.188.168-.364.313-.51.263-.262.612-.407.983-.407zm17.707 14.317c-.263.263-.611.407-.982.407h-16.725c-.371 0-.72-.145-.982-.407-.262-.263-.407-.611-.407-.982v-10.077l9.196 6.131c.168.112.361.168.555.168s.387-.056.555-.168l9.197-6.131v10.077c0 .371-.145.72-.407.982z"
            fill="#fff"
          />
        </>
      ),
      href: `mailto:?subject=${title}&body=${title} (${url})`,
    },
    linkedin: {
      icon: (
        <>
          <rect width="48" height="48" fill="#007ebb" />
          <path
            d="m41.333 41.333h-7.123v-12.132c0-3.326-1.264-5.185-3.897-5.185-2.864 0-4.36 1.934-4.36 5.185v12.132h-6.864v-23.111h6.864v3.113s2.064-3.819 6.968-3.819c4.902 0 8.412 2.994 8.412 9.185zm-30.433-26.137c-2.338 0-4.233-1.91-4.233-4.265s1.894-4.264 4.233-4.264 4.232 1.91 4.232 4.265-1.894 4.264-4.232 4.264zm-3.545 26.137h7.158v-23.111h-7.158z"
            fill="#fff"
          />
        </>
      ),
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    },
  }

  return content
}

interface Props {
  url: string
  title: string
}

export const ShareIcons = ({ url, title }: Props) => {
  const content = getContent(url, title)

  return (
    <Container>
      {Object.keys(content).map(service => (
        <Item key={service} href={content[service].href} target="_blank" rel="noreferer">
          <svg fill="none" height="48" viewBox="0 0 48 48" width="48">
            {content[service].icon}
          </svg>
        </Item>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  cursor: pointer;
`

const Item = styled.a`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 8px;

  ${hoverMedia} {
    transition: 0.2s ease-in-out;
    transition-property: opacity, filter;
    &:hover {
      filter: grayscale(1);
      opacity: 0.6;
    }
  }
`