import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { useCloseSearchHandler } from '../../../../useCloseSearchHandler'
import { SearchResultsData } from '../types'
import { colors, desktopSmallMedia } from '@/lib/theme'

export const CustomHit = ({ hit }: { hit: SearchResultsData }) => {
  const closeSearchHandler = useCloseSearchHandler()

  return (
    <Container>
      <Link to={hit.url} onClick={closeSearchHandler}>
        <Text>{hit.title}</Text>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  user-select: none;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.2s ease-in-out;

  ${desktopSmallMedia} {
    margin: 0 20px;
  }

  &:hover {
    color: ${colors.primary.primary};
  }

  & a {
    width: 100%;
    padding: 16px 0;
    cursor: pointer;
  }
`

const Text = styled.span`
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  text-align: left;
`
